import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import './authErrorPage.css';
import { Box, Grid, Typography, Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import { PAGE_BUILDER_ENDPOINTS } from '../../core/constants';
import logoImage from "../../images/logo_pagebuilder.svg";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const AuthErrorPage = () => {
  const originUrl = window.location.origin;
  
  let params = new URLSearchParams(window.location.search);
  let userEmail = params.get('email');
  let capitalizedName = '';
  if (userEmail) {
    let name = userEmail.split(".")[0];
    capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

  }
  const redirectToAnotherPage = () => {
    // Use the history object to navigate to another page
    window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/logout?ru=" + originUrl; // Replace '/another-page' with the actual URL
  };
  return (

    <React.Fragment>
      {/* <div className="auth-error-container">
      <div className="auth-error-msg">Access Denied</div>
      <div className="auth-error-message-container">
        <div className="auth-error-message">
          
          <br />
          </div>
      </div>
      
    </div> */}
      <Box sx={{ flexGrow: 1 }} className="auth-header">
        <AppBar position="relative" className='auth-header-top'>
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography component="a" sx={{ flexGrow: 1 }} className='logo'>
              <img src={logoImage} alt='logo' width={96} height={36} />
            </Typography>
            <Typography component="div" className='auth-user'>
              <span className='username'>{capitalizedName}</span>
              <Tooltip title="Log out" arrow placement="top">
                <IconButton onClick={redirectToAnotherPage}><SettingsPowerIcon /></IconButton>
              </Tooltip>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Box sx={{ flexGrow: 1 }} className="auth-card-wrap">
        <Grid container justifyContent="center" direction="row" spacing={0}>
          <Grid item>
            <Card variant="elevation" sx={{ maxWidth: 385 }} className='auth-error-card'>
              <CardContent>
                <CardMedia className='icon'
                  component="img"
                  alt="access denied"
                  height={50}
                  width={53}
                  maxWidth={50}
                  image="https://qa1origin.getm360.com/stresources/images/access-denied.svg"
                />
                <Typography sx={{ fontSize: 20 }} variant='h2' className='auth-title'>
                  Access Denied
                </Typography>
                <Typography className='auth-msg'>
                  We're sorry. You don't have permission to access this feature.
                  <br />
                  Please contact support for assistance.
                </Typography>
              </CardContent>
              <CardActions>
                <Button className='auto-button' size="medium" variant="contained" onClick={redirectToAnotherPage}>Back to Login</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default AuthErrorPage;