import React, { useEffect, useState } from 'react';
import Home from './Home';
import { getUserSession } from '../../core/Utils';
import { CONST_PERMISSIONS, PAGE_BUILDER_ENDPOINTS } from '../../core/constants';
const YOUR_RETURN_URL = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/userAuthenticate";
import { updateSessionObject } from "../../core/Utils";

const HomeWrapper = () => {
    const [userSession, setUserSession] = useState(null);
    const originUrl = window.location.origin;

    useEffect(() => {
        let host = window.location.href;
        getUserSession()
            .then(response => {
                if (response != null && response) {
                    setUserSession(response);
                    updateSessionObject("metaData", response);
                    // if(response.clientList) updateSessionObject("metaData", response.clientList, "clientList");
                    // if(response.jwtToken) updateSessionObject("metaData", response.jwtToken, "jwtToken");
                    // if(response.name) updateSessionObject("metaData", response.name, "name");
                    // if(response.permissions) updateSessionObject("metaData", response.permissions, "permissions");
                    // if(response.tenantIdPermissions) updateSessionObject("metaData", response.tenantIdPermissions, "tenantIdPermissions");
                    // if(response.tokenPayload) updateSessionObject("metaData", response.tokenPayload, "tokenPayload");
                    // if(response.websiteList) updateSessionObject("metaData", response.websiteList, "websiteList");
                    if(response.tokenPayload && response.tokenPayload.hostId) updateSessionObject("hostId", response.tokenPayload.hostId);
                } else {
                    window.iamAuthSDK.login();
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                }
            })
            .catch(error => {
                console.error("Error while getting session data: ", error);
                if (error.response && error.response.status === 401) {
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                    window.iamAuthSDK.login();
                }
            });
    }, []);

    if (!userSession) {
        return null;
    }

    if (
        userSession.permissions !== undefined && userSession.permissions && userSession.permissions.length > 0 
        //&&
        //userSession.tenantIdPermissions &&
        //userSession.permissions.find((op) => op.hostId === userSession.tokenPayload.hostId) !== undefined &&
        //userSession.permissions.find((op) => op.hostId === userSession.tokenPayload.hostId).permission.includes(CONST_PERMISSIONS.PAGE_BUILDER)
        //userSession.tenantIdPermissions && userSession.tenantIdPermissions.includes(CONST_PERMISSIONS.PAGE_BUILDER)
        ) {
        return <Home />;
    } else {
        window.location.href = originUrl + "/dashboard";
        return null;
    }
};

export default HomeWrapper;
