export let API_URLS = {
  CDS_API: process.env.REACT_APP_CDS_API,
  NEWSROOM_API: process.env.REACT_APP_NEWSROOM_API,
  IAM_DOMAIN: process.env.REACT_APP_IAM_DOMAIN,
  DENMARK_URL: process.env.REACT_APP_DENMARK_URL,
  VSP_IMAGES: process.env.REACT_APP_VSP_IMAGES,
  ST_RESOURCES: process.env.REACT_APP_ST_RESOURCES,
  BREAKING_TICKER_URL: process.env.REACT_APP_BREAKING_TICKER_URL
}

console.log("API_URLS", API_URLS, process.env);

export let CONSTANTS = {
  ENV: {
    M360_STAGING: "M360_STAGING",
    M360_PROD: "M360_PROD",
  },

  API_URL: {
    CDS_API: API_URLS.CDS_API,
    VSP_IMAGES: API_URLS.VSP_IMAGES,
    ST_RESOURCES: API_URLS.ST_RESOURCES,
    BREAKING_TICKER_URL: API_URLS.BREAKING_TICKER_URL
  },

  COMPONENTS_CATEGORY: {
    COMPONENTS: "Components",
    LAYOUTS: "layouts",
  },

  SCROLL_MODE: {
    AUTOMATIC: "automatic",
    MANUAL: "manual",
  },
};

//console.log("API_URLS", API_URLS);

export const RENDERING_ENDPOINTS = {
  BREAKING_NEWS_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  ARTICLE_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/article/m360`,
  QUIZ_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/quiz/m360`,
  SURVEY_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/survey/m360`,
  HEADER_DATA_API: `${API_URLS.NEWSROOM_API}/getsiteResources`,
  HEADER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  TOGGLER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  FOOTER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,

  LISTING_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  BREAD_CRUMBS_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  AUTHOR_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/author/details/`,  //479262192?hostid=387
  AUTHOR_LISTING_API: `${API_URLS.CDS_API}/cds-feed-ext/author/story/list/`,
  KNOWLEDGE_SEARCH_API: `${API_URLS.CDS_API}/cds-feed-ext/jcms/`,
  ORION_SEARCH_API: `${API_URLS.CDS_API}/cds-feed-ext/jcms/orion`,
  VIDEO_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/video/m360`,
  LISTING_DETAIL_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  LISTING_DETAIL_API_SH: `${API_URLS.CDS_API}/cds-feed-ext/section/get/m360`,
  MENU_MANAGEMENT_SECTION_SEARCH_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  MENU_MANAGEMENT_HEADER_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  BREAKING_NEWS_TICKER_API: `${API_URLS.BREAKING_TICKER_URL}/breakingnews`


};

export const PAGE_BUILDER_ENDPOINTS = {
  BREAKING_NEWS_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  HEADER_DATA_API: `${API_URLS.NEWSROOM_API}/getsiteResources`,
  HEADER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  TOGGLER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  FOOTER_MENU_API: `${API_URLS.CDS_API}/cds-feed-ext/moderate/ux/hf/m360/get`,
  QUIZ_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/quiz/m360`,
  SURVEY_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/survey/m360`,
  LISTING_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  BREAD_CRUMBS_API: `${API_URLS.CDS_API}/cds-feed-ext/listing/get/m360`,
  AUTHOR_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/author/details/`,  //479262192?hostid=387
  AUTHOR_LISTING_API: `${API_URLS.CDS_API}/cds-feed-ext/author/story/list/`,
  KNOWLEDGE_SEARCH_API: `${API_URLS.CDS_API}/cds-feed-ext/jcms/`,
  ORION_SEARCH_API: `${API_URLS.CDS_API}/cds-feed-ext/jcms/orion`,
  VIDEO_SHOW_API: `${API_URLS.CDS_API}/cds-feed-ext/show/video/m360`,
  DOMAIN: `${API_URLS.NEWSROOM_API}`,
  IAM_DOMAIN: API_URLS.IAM_DOMAIN,
  DENMARK_SEARCH_NEWSROOM: `${API_URLS.NEWSROOM_API}/denmarkSearch`,
  MENU_MANAGEMENT_UPDATE_API: `${API_URLS.NEWSROOM_API}/menuManagement/updateMenu`,
  MENU_MANAGEMENT_GET_API: `${API_URLS.NEWSROOM_API}/menuManagement/getMenu`,
  MENU_MANAGEMENT_SEOPATH_API: `${API_URLS.CDS_API}/cds-feed-ext/show/article/m360`,
  MENU_MANAGEMENT_TAGCHECK_API: `${API_URLS.CDS_API}/cds-feed-ext/jcms/keyword`,
  FEED_MANAGEMENT_GETALLFEEDS: `${API_URLS.NEWSROOM_API}/feeds/getAllFeedsByWebsite`,
  FEED_MANAGEMENT_UPDATESTATUS: `${API_URLS.NEWSROOM_API}/feeds/status`,
  BREAKING_NEWS_TICKER_API: `${API_URLS.BREAKING_TICKER_URL}/breakingnews`,
  LISTING_DETAIL_API_SH: `${API_URLS.CDS_API}/cds-feed-ext/section/get/m360`,
  IMAGE_VALIDATOR_API: `${API_URLS.CDS_API}/cds-feed-ext/show/article/m360`
};

export const Filtering_Type = [
  { label: "Priority", value: "p" },
  { label: "Combo Priority", value: "cp" },
  { label: "Hierarchy", value: "h" },
  { label: "Leaf Hierarchy", value: "lh" },
  { label: "Combo Hierarchy", value: "ch" },
  // { label: "Section Hierarchy", value: "sh" },
  { label: "Tag Search", value: "keyword-search" },
]

//This is the data mapping trait
export const DATA_MAPPING_TRAIT = {
  type: "data-mapping-trait",
  name: "dataMappingTrait",
  changeProp: 1,
  options: [
    [
      { label: "Hierarchy", value: "h" },
      { label: "Priority", value: "p" },
      { label: "Combo Priority", value: "cp" },
      { label: "Leaf Hierarchy", value: "lh" },
      { label: "Combo Hierarchy", value: "ch" },
      { label: "Section Hierarchy", value: "sh" },
      { label: "Special Section Hierarchy", value: "ssh" },
      { label: "Author Listing", value: "auth-list" },
      { label: "Knowledge Search", value: "knowledge-search" },
      { label: "Keyword Search", value: "keyword-search" },
      { label: "Orion Search", value: "orian-search" },
    ],
    [
      { label: "Article", value: "article" },
      { label: "Video", value: "video" },
      { label: "Slideshow", value: "slideshow" },
      { label: "Podcast", value: "audio" },
      { label: "Webstory", value: "webstory" },
      { label: "Immersive Story", value: "immersivestory"},
      { label: "quiz", value: "quiz" },
      { label: "survey", value: "survey"}
    ],
    [
      { label: "Published Time", value: "date" },
      { label: "Updated Time", value: "updatedate" },
    ],
    [
      { label: "Descending", value: "dsc" },
      { label: "Ascending", value: "asc" },
    ],
  ],
  value: {
    componentBinder: "static",
    parentComponent: null,
    urlfield: "msid",
    mappingType: "h",
    selectContainer: "",
    storyType: ["article", "video", "slideshow", "audio", "immersivestory"],
    sortType: "date",
    sortOrder: "dsc",
    attributeType: "",
    denmarkSearchedItems: [],
    isChecked: true
  },
};

//These are the color sets available in Global Settings
export const colorSetOptionsList = [
  {
    colorSetName: "ColorSet",
    colorSetNumber: "1",
    colorSetValue: {
      "--primary": "#DC1B07",
      "--secondary": "#727272",
      "--tertiary": "#212121",
      "--primaryPale": "#FBE3E1",
      "--textColor": "#979797",
      "--textPale": "#ADADAD",
      "--bgColor": "#FAFAFA",
      "--bgRColor": "#000",
      "--border": "#DADADA",
      "--tagColor": "#F3F3F3",
      "--alertColor": "#FEFFEF",
      "--linkColor": "#1276D3",
      "--activeColor": "#727272",
      "--inActiveColor": "#BFBFBF",
    },
  },
  {
    colorSetName: "ColorSet",
    colorSetNumber: "2",
    colorSetValue: {
      "--primary": "#00539A",
      "--secondary": "#808180",
      "--tertiary": "#040E13",
      "--primaryPale": "#DEEBF2",
      "--textColor": "#696969",
      "--textPale": "#939393",
      "--bgColor": "#F5FBFF",
      "--bgRColor": "#000",
      "--border": "#BFBFBF",
      "--tagColor": "#FAFAFA",
      "--alertColor": "#FEFFEF",
      "--linkColor": "#303075",
      "--activeColor": "#ADADAD",
      "--inActiveColor": "#DADADA",
    },
  },
];

export const defaultFontSizes = {
  h1: 26,
  h2: 20,
  h3: 18,
  h4: 16,
  h5: 14,
  h6: 12,
  p: 14,
};

export const defaultFontWeight = {
  h1: 700,
  h2: 700,
  h3: 700,
  h4: 600,
  h5: 400,
  h6: 400,
  p: 400,
};

export const GLOBAL_FONT_WEIGHT = `<option value="400">Normal</option>
  <option value="100">Thin</option>
  <option value="200">Extra-Light</option>
  <option value="300">Light</option>
  <option value="500">Medium</option>
  <option value="600">Semi-Bold</option>
  <option value="700">Bold</option>
  <option value="800">Extra-Bold</option>
  <option value="900">Ultra-Bold</option>`;

export const GLOBAL_LINE_SPACING = `<option value="inherit">inherit</option>
  <option value="initial">initial</option>
  <option value="normal">normal</option>
  <option value="unset">unset</option>`;

export const GLOBAL_FONT_FAMILY = `<option value="Poppins">Poppins</option>
  <option value="Arial, Helvetica, sans-serif">Arial</option>
  <option value="Arial Black, Gadget, sans-serif">Arial Black</option>
  <option value="Brush Script MT, sans-serif">Brush Script MT</option>
  <option value="Comic Sans MS, cursive, sans-serif">Comic Sans MS</option>
  <option value="Courier New, Courier, monospace">Courier New</option>
  <option value="Georgia, serif">Georgia</option>
  <option value="Helvetica, sans-serif">Helvetica</option>
  <option value="Impact, Charcoal, sans-serif">Impact</option>
  <option value="Lucida Sans Unicode, Lucida Grande, sans-serif">Lucida Sans Unicode</option>
  <option value="Tahoma, Geneva, sans-serif">Tahoma</option>
  <option value="Times New Roman, Times, serif">Times New Roman</option>
  <option value="Trebuchet MS, Helvetica, sans-serif">Trebuchet MS</option>
  <option value="Verdana, Geneva, sans-serif">Verdana</option>`;

export const GLOBAL_DATE_FORMAT = `<option value="MMM DD, YYYY, HH:MM TZ">MMM DD, YYYY, HH:MM TZ</option>
  <option value="DD MMM YYYY, HH:MM TZ">DD MMM YYYY, HH:MM TZ</option>
  `;

export const PARENT_COMPONENTS_DATA_MAPPING = [
  "List View",
  "Article Show",
  "Video Show",
  "Author Show",
  "Author Show Two",
  "Author Show Three",
  "Slide Show",
  "Article Show Two",
  "Article Show Three",
  "List View Two",
  "List View Three",
  "Video Show Two",
  "Video Show Three",
  "Author Show Two",
  "Author Show Three",
  "Slide Show Two",
  "Slide Show Three",
  "Podcast Two",
  "Podcast Three"
];

export const SITE_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Telugu', value: 'te' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Bengali', value: 'bn' },
];

export const CONST_PERMISSIONS = {
  PAGE_BUILDER: 1,
  WEBSITE_CHANNEL_CREATION: 2
}