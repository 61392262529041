import React, { useEffect, useRef, useState,} from 'react'
import {createSessionifMissing, getMUICache, loadStyles, httpMiddleware, logoutUserSession } from '../../core/Utils';
import Grid from '@mui/material/Grid';
import { CacheProvider } from '@emotion/react';
// import DashBoard from './DashBoard.css'
import LandingPage from './LandingPage.css'
import logoImage from "../../images/dashboard-logo.svg";
import OrgIcon from "../../images/org-icon.png";
import AppsIcon from '@mui/icons-material/Apps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { PAGE_BUILDER_ENDPOINTS, CONSTANTS } from "../../core/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';



const  LandingMainPage = (props) => {
    const cache = getMUICache();
    const [show, setShow]=useState(false);
    const [dropShow, setDropShow]=useState(false);
    const [webListShow, setWebListShow]=useState(false);
    const[activeState, desabileState]=useState(false);
    const[activeStateLog, desabileStateLog]=useState(false);
    const[activeWebList, desabileWebList]=useState(false);
    const [userEmail, setUserEmail] = useState("");
    const refOne=useRef(null);
    const refTwo=useRef(null);
    const refThree=useRef(null);
    const [websitOptions, setOptions] = useState([]);
    const link = document.querySelector("link[rel~='icon']");


    function handleClick(){
        desabileState(activeState => !activeState);
    }
    function handleLogout(){
        desabileStateLog(activeStateLog => !activeStateLog);
    }
    function handleWebList(){
        desabileWebList(activeWebList => !activeWebList);
    }
    useEffect(() => {
        createSessionifMissing().then( res => {
            if( res && res && res?.tokenPayload?.userEmail ) {
                let userEmail = res.tokenPayload.userEmail;                
                setUserEmail(userEmail);

                document.addEventListener("click" , handleClickOutside, true);

                // document.addEventListener("click" , handleClickLogButton, true);
                document.addEventListener("click" , handleClickWebList, true);

                // Make an API request to fetch your data
                const config = {
                    method: 'GET',
                    url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getAllWebsitesByClient",
                    };
                
                    httpMiddleware(config)
                    .then((response) => {
                        if (!response.status) {
                        throw new Error('Network response was not ok');
                        }
                
                        return response.data;
                    })
                    .then((data) => {
                        setOptions(data);
                        // console.log("akhvsb jlk: ", websitOptions);
                        // sessionStorage.setItem('currentWebsiteList', JSON.stringify(data));
                        // handleSelectChange(data[0], "firstLoad"); // Update the options state with the data from the API
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
                    }
                } );
                // add newtitle
                document.title = 'PublishStory Dashboard';

                
                if (link !== null) {
                  link.href = 'https://pagebuilder.publishstory.co/stresources/custom-icons/PS%20favicon.ico'; // Replace '/favicon-page1.ico' with your favicon path for Page 1
                }
    }, []);

    const handleClickOutside = (e) =>{
        if(refOne && refOne.current && refOne.current.contains(e.target)){
            // console.log("clicked in side one")
        }else{
            // console.log("clicked out side one")
            setShow(0);
        }

    }

    // const handleClickLogButton = (e) =>{

    //     if(refTwo && refTwo.current && refTwo.current.contains(e.target)){
    //         console.log("clicked in side two")
    //     }else{
    //         console.log("clicked out side two")
    //         setDropShow(0);
    //     }

    // }

    const handleClickWebList = (e) =>{

        if(refThree && refTwo.current && refTwo.current.contains(e.target)){
            // console.log("clicked in side three")
        }else{
            // console.log("clicked out side two")
            setWebListShow(0);
        }

    }


    function getInitials(input) {
        const words = input.split(" ");
        const initials = words.map(word => word.charAt(0));
        if (initials.length === 1) {
          return initials[0].toUpperCase();
        }
        return (initials[0] + initials[1]).toUpperCase();
      }
      
    let company_name = "Company Name";
    let company_email = "company.name@gmail.com";
    let company_name_initials = "CN";
    if (sessionStorage.getItem("metaData") && JSON.parse(sessionStorage.getItem('metaData')).name) {
        company_name = JSON.parse(sessionStorage.getItem('metaData')).name;
        company_name_initials = getInitials(company_name);
        if(JSON.parse(sessionStorage.getItem("metaData")).tokenPayload && JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail) {
            company_email = JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail;
        }
    }

    function getlogoImageUrl(data) {
        console.log("CONSTANTS : ", CONSTANTS);
        if(data.websiteDomain && data.websiteResouces && data.websiteResouces.schemaLogo && CONSTANTS && CONSTANTS.API_URL && CONSTANTS.API_URL.VSP_IMAGES) {
            if(CONSTANTS.API_URL.VSP_IMAGES == "") {
                return 'https://' + data.websiteDomain + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            } else {
                return CONSTANTS.API_URL.VSP_IMAGES + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            }
        } else {
            return OrgIcon
        }
    }

    const [pageTemplateData, setPageTemplateData] = useState(null);
    const dymincUrl = "https://pagebuilder.publishstory.co";

      const landingNinedotsJson=[
        // {
        //     mediaPath:dymincUrl + "/stresources/custom-icons/home.svg",
        //     mediaHoverPath:dymincUrl + "/stresources/custom-icons/home_selected.svg",
        //     links:[
        //         {
        //             title: "Home",
        //             path:"https://app.publishstory.co/dashboard/"
        //         }
        //     ]
        // },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Authoring_denmark.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Authoring",
                    path:"https://app.publishstory.co/cms/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Reporting_cmsanalytics.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Reporting",
                    path:"https://app.publishstory.co/reporting/cms-analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Analytics_ibeat.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/cmsanalytics_selected.svg",
            links:[
                {
                    title: "Analytics",
                    path:"https://app.publishstory.co/analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Pagebuilder_m360.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/dots-page_builder_selected.svg",
            links:[
                {
                    title: "Page Builder",
                    path:"https://app.publishstory.co/pagebuilder/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/assetmanager_chronicle.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Asset Manager",
                    path:"/"
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Admin_coral.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/Chronicle_selected.svg",
            links:[
                {
                    title: "Admin",
                    path:"https://app.publishstory.co/admin/"
                }
            ]
        },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Comment moderator.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Comment Moderator",
                    path:"/", 
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/VideoAuthoring_slike.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Video Authoring",
                    path:"/"
                }
            ]
        }

      ]


      const footerLinksJson = [
        {
          links: [
            {
              title: "All rights reserved",
            //   path: "/dashboard/copyright"
            },
            {
                title: "Privacy Policy",
                path: "/dashboard/privacy-policy"
              },
              {
                title: "Terms & Conditions and Grievance Redressal Policy",
                path: "/dashboard/terms-conditions"
              },
              {
                title: "About us",
                // path: "/dashboard/about"
              }

          ]
        },

      ]
// /thumb/104894223.cms?imgsize=422424&amp;width=96&amp;resizemode=4

    if(!(userEmail)) {
        return <></>
    }

    return(
        <>
         <div className='MainBodyWrapper' styles={LandingPage}>
            <div className='LandingPageHeader'>
                <div className='LandingPageHeaderWrapper'>
                    <div className='LandingPageLogo'>
                        <figure><img src={logoImage} /></figure>
                    </div>
                    <div className='RightSideMenuWrap'>
                        <div className='MenuSelectItems'>
                        <p className='inNewsroomTiitle'>Newsroom</p>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <Select labelId="SelectPublisher" id="SelectPublisherHelper" label="publishers" defaultValue={0}>
                                {websitOptions.map((data, i) =>{
                                        return <MenuItem key={ 'jj_' + i} className='slectItemsCommon' value={i}>
                                            <img src={getlogoImageUrl(data)}/>
                                            {data.websiteName}</MenuItem>
                                       
                                })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className='moreAppsWrapper' id='_9dots_more_apps_wrapper'></div>

                        {/* <div className={`AppsIconsWrap ${activeState ? "activeState": ''}`} onClick={handleClick}>
                            <button onClick= {()=>setShow(!show)} className='dotsButton' ref={refOne}> <AppsIcon></AppsIcon></button>
                            {show? <div className='ShowIconsOnClick'>
                                <ul>
                                {landingNinedotsJson.map((data, i) =>{
                                    console.log('data',data);
                                    
                                    return <li key={i + "1"}>
                                        {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                       return <a href={item.path} target='_blank' key={l + "3"}><i className='IconWrap'>
                                       <figure className='nineDotsIcon'><img src={data.mediaPath} /></figure>
                                       <figure className='nineDotsIconSelected'><img src={data.mediaHoverPath} /></figure>
                                       </i><span>{item.title}</span></a>
                                    })}
                                    </li>
                                   
                                })}
                                </ul>
                                 
                            </div>:null
                            }
                        </div> */}

                        <div className={`UserNameWrap ${activeStateLog ? "activeStateLog": ''}`} onClick={handleLogout}>
                            <button className='logButtonDrop' onClick= {()=>setDropShow(!dropShow)} ref={refTwo}>{company_name_initials}</button>
                            {dropShow? <div className='logOutWrap'>
                            <div className='userDetailsWrap'>
                            <div className='userNameDetails'>{company_name}</div>  
                              <div className='userEmailDetails'>{company_email}</div>
                            </div>
                            <Tooltip title="Logout">
                            <button onClick={logoutUserSession}><FontAwesomeIcon icon={faPowerOff} /> Logout</button>
                            </Tooltip>
                            </div>:null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='fix-wrapper'>
                <CacheProvider value={cache}>
                    <Grid container>
                    </Grid>
                </CacheProvider>
            </div>
            <div className='landingPageFooter'>
                <CacheProvider value={cache}>
                <Grid container>
                        <Grid item md={12} xs={12}>
                            <div className='landingFooterText'>
                                <p>publishstory.co , Copyright © 2024 Times Internet Ltd.</p>
                            </div>
                            
                            <div className='landingFooterLinks'>
                                    <ul>
                                    {footerLinksJson.map((data, i) =>{
                                        console.log('data',data);
                                        
                                        return <li>
                                            {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                        return <a rel="noopener noreferrer" target='_blank' href={item.path}><span>{item.title}</span></a>
                                        })}
                                        </li>
                                    
                                    })}
                                    </ul>
                            </div>
                            </Grid>
                            </Grid>
                </CacheProvider>
            </div>
        </div>
        </>
    )

}

export default LandingMainPage;

