import React, { useEffect} from 'react';
import { validateAdScript } from '../../core/Utils'; 

function AddScripts(props, ref) {

    let {
        headerScript,
        bodyScript,
        // compHeight,
        compWidth,
        // parentHeight,
        // parentWidth
        adSize
    } = props;

    useEffect(() => {
        if (typeof headerScript !== "undefined") {
            //console.log("headerScript value : ", headerScript);
            if (!validateAdScript(headerScript)) {
                // showErrorToast("Header script is not correct. Please verify!!");
                // document.getElementById('headerScript').innerHTML = '';
                return;
            }
        }
    }, [headerScript]);

    useEffect(() => {
        if (typeof bodyScript !== "undefined") {
            //console.log("bodyScript value : ", bodyScript);
            if (!validateAdScript(bodyScript)) {
                // showErrorToast("Body script is not correct. Please verify!!");
                // document.getElementById('headerScript').innerHTML = '';
                return;
            }
        }
    }, [bodyScript]);
    // let dimensions = "";
    // if (parentHeight !== undefined && compWidth !== undefined) {
    //     dimensions = `Max Dim Ad Supported: ${compWidth} X ${parentHeight}`;
    //     if (compWidth > 300 && compWidth < 400) {
    //         dimensions += `<br />Best Supported Dim Ad: 300*100,300*250`
    //     } else if (compWidth > 970) {
    //         dimensions += `<br />Best Supported Dim Ad: 970*90`
    //     }

    // }
    //dimensions = `Comp Dim: ${compWidth} X ${compHeight}<br /> Parent Dim: ${parentWidth} X ${parentHeight}`;

    if (compWidth !== undefined) {
        if (compWidth > 970) {
            adSize = `970*90`
        }
    }
    const width = parseInt(adSize.split("*")[0]);
    const height = parseInt(adSize.split("*")[1]);

    return (
        <div className="container" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: width,
            minHeight: height,
            backgroundColor: "#f0f0f0",
            margin: "0px auto"
        }}>
            <div className="ad-box">
                Ad Slot<br />
                {adSize}
            </div>
        </div>
    );
}

export default AddScripts;