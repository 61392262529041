import React, { useState, useEffect } from "react";
import "./App.css";
import HomeWrapper from "./pages/Home/HomeWrapper";
import NewsRoom from "./pages/Newsroom";
// import MainFooter from "./components/Footer/Footer";
// import Material from "./pages/Material";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login/Login";
import HealthCheck from "./core/HealthCheck";
import AuthErrorPage from "./components/Authentication/AuthErrorPage";
import PageNotFound from "./components/PageNotFound/Error";
import LandingPage from "./components/LandingPage/LandingPage";
import FeedExportPage from "./components/LandingPage/FeedExportPage";
import AboutUs from "./components/LandingPage/AboutUs";
import CopyRight from "./components/LandingPage/CopyRight";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy";
import TermsConditions from "./components/LandingPage/TermsConditions";
import Versioning from "./components/Versioning";
import { setLocalStorage } from "./core/Utils";

/*-----------theme 3 design----------*/
// add extra class on body

/*-----------theme 3 design----------*/

const App = () => {
  const [isReady, setIsReady] = useState(false);
  let isSDKReady = false;

  console.log("APPPPPP", process.env);

  if (window.location.href.indexOf("#")
    && window.location.href.split("#")[1] !== undefined) {
    setLocalStorage("hash", window.location.href.split("#")[1])
  }

  if( process.env.REACT_APP_MODE && process.env.REACT_APP_MODE == 'PROD' ) {
    //require( 'newrelic' );
  }

  function checkSDKReadiness() {
    try {
      !isReady && window.iamAuthSDK.isReady(function (data) {
        isSDKReady = true;
        setIsReady(true);
        console.log("IAM init");
      });
    } catch (error) {
      console.log("error in window.iamAuthSDK", error, isSDKReady, isReady);
    }
  }


//   function removeDataSrcFromImages() {
//     const images = document.querySelectorAll('img[data-src]');
//     if (images.length === 0) {
//         console.log('No images with data-src found.');
//         return; 
//     }
    
//     images.forEach((img) => {
//         const dataSrc = img.getAttribute('data-src');
//         if (dataSrc) {
//             img.setAttribute('src', dataSrc);
//             img.removeAttribute('data-src');
//             console.log('Processed image:', img, 'src:', dataSrc);
//         }
//     });
// }

// removeDataSrcFromImages();

// Function to remove data-src from images
function removeDataSrcFromImages(document) {
  const images = document.querySelectorAll('img[data-src]');
  if (images.length === 0) {
      //console.log('No images with data-src found.');
      return; // Exit if no images are found
  }
  
  images.forEach((img) => {
      const dataSrc = img.getAttribute('data-src');
      if (dataSrc) {
          img.setAttribute('src', dataSrc);
          img.removeAttribute('data-src');
          // console.log('Processed image:', img, 'src:', dataSrc);
      }
  });
}

// Function to check for the iframe
function checkForIframe() {
  const iframeWrapper = document.querySelector('div.gjs-frames');
  if (iframeWrapper) {
    const iframe = iframeWrapper.querySelector('iframe'); 
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      //console.log('Iframe found with div. Running removeDataSrcFromImages...');
      removeDataSrcFromImages(iframeDocument);
  }
}

// Create a MutationObserver to watch for changes in the DOM
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
          checkForIframe(); // Check for the iframe whenever the DOM changes
      }
  });
});

// Start observing the body for changes
observer.observe(document.body, { childList: true, subtree: true });

// Initial check for the iframe
setTimeout(() => {
  checkForIframe();
}, 2000); // Adjust the timeout as needed



  const getRoutes = () => {
    return <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/pagebuilder" />} />
        <Route path="/check" element={<HealthCheck />} />
        <Route path="/pagebuilder" element={<Login />} />
        <Route path="/:section1/login/callback" element={<Login />} />
        <Route path="/:section1/:section2/login/callback" element={<Login />} />
        <Route path="/pagebuilder/newsRoom" element={<NewsRoom />} />
        <Route path="/pagebuilder/404" element={<PageNotFound />} />
        <Route path="/dashboard" element={<LandingPage />} />
        <Route path="/dashboard/customfeed" element={<FeedExportPage />} />
        <Route path="/customfeed" element={<FeedExportPage />} />
        <Route path="/dashboard/about" element={<AboutUs />} />
        <Route path="/dashboard/copyright" element={<CopyRight />} />
        <Route path="/dashboard/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/dashboard/terms-conditions" element={<TermsConditions />} />
        <Route path="/pagebuilder/Home" element={<HomeWrapper />} />
        <Route path="/:section1/authCheck" element={<AuthErrorPage />} />
        <Route path="/:section1/:section2/authCheck" element={<AuthErrorPage />} />
        {/* <Route path="/webstry" element={<WebStroiesPage />} /> */}
        <Route path="/pagebuilder/versioning" element={<Versioning />} />

        {/* theme 3  */}


        {/* theme 3  */}
        
      </Routes>
      <ToastContainer />
    </Router>
  }

  checkSDKReadiness();

  return (
    <div>
      {isReady ? getRoutes() : null}
    </div>
  );
};

export default App;

