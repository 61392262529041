import { CONSTANTS, PAGE_BUILDER_ENDPOINTS, RENDERING_ENDPOINTS, DATA_MAPPING_TRAIT } from "./constants"; 
import BreakingNewsIcon from "../images/custom-icons/breaking_news.svg";
import GridViewIcon from "../images/custom-icons/grid.svg";
import GridViewIconLess from "../images/custom-icons/grid_viewless.svg";
import HeaderIcon from "../images/custom-icons/header.svg";
import FooterIcon from "../images/custom-icons/footer.svg";
import HeroLayoutIcon from "../images/custom-icons/hero_layout.svg";
import HeroLayoutSecondIcon from "../images/custom-icons/Hero_layout_new.svg";
import CarouselCardIcon from "../images/custom-icons/carousel_card.svg";
import ListViewIcon from "../images/custom-icons/list_view.svg";
import SimilarStoriesIcon from "../images/custom-icons/similar_stories.svg";
import ArticleShowIcon from "../images/custom-icons/article_show.svg";
import VideoStoriesIcon from "../images/custom-icons/video_stories.svg";
import BreadCrumbsIcon from "../images/custom-icons/bread_crumbs.svg";
import ErrorPageIcon from "../images/custom-icons/error_page.svg";
import AuthorShowIcon from "../images/custom-icons/author_show.svg";
import SlideShowIcon from "../images/custom-icons/slide_show.svg";
import StaticPageIcon from "../images/custom-icons/static_page.svg";
import AddScriptIcon from "../images/custom-icons/add_script.svg";
import { getDefaultMsids, getSessionObject } from "./Utils";
//const { defaultPriortyListMsid, defaultListingMsid, defaultArticleMsid, defaultVideoMsid, defaultSlideshowMsid, defaultAuthorID, defaultStaticMsid } = await getDefaultMsids();

const createComponentJson = async () => {

  return new Promise((resolve) => {

    let metaData = sessionStorage.getItem("metaData");
    let data = metaData != null ? JSON.parse(metaData) : "";
    let retrievedWebsiteId = data.websiteId;
    let retrievedClientId = data.clientId;
    let tenantId = data.tokenPayload?.tenantId;
    let componentJson = [];

    if (retrievedClientId || retrievedWebsiteId) {
      let { defaultSystemHostId, defaultKeywords, defaultPriortyListMsid, defaultListingMsid, defaultArticleMsid, defaultVideoMsid, defaultSlideshowMsid, defaultAuthorID, defaultStaticMsid, themeId, themeName, defaultQuizId, defaultSurveyId } = getDefaultMsids() || {};
      // let defaultQuizId = 113414846; 
      // let defaultQuizHostId = 421; 
      // let defaultSurveyId = 112466373;
      // let defaultSurveyHostId = 421;
      localStorage.setItem("themeId", themeId);
      //defaultSystemHostId = 387;
      const retrievedHostId = sessionStorage.getItem('hostId');
      const userHostId = (retrievedHostId !== null && retrievedHostId !== undefined && retrievedHostId !== '0') ? retrievedHostId : '0';
      const defaultHostId = defaultSystemHostId;

      const themeClassName = "theme_" + themeId;
      let iframes = document.getElementsByClassName("gjs-frame");
      if (iframes && iframes[0] && iframes[0].contentWindow) {
        for (let classNam of iframes[0].contentWindow.document.body.classList) {
          if (classNam.includes("theme_")) {
            iframes[0].contentWindow.document.body.classList.remove(classNam);
          }
        }
        iframes[0].contentWindow.document.body.classList.add(themeClassName);
      }

      let defaultIds = [
        defaultPriortyListMsid,
        defaultListingMsid,
        defaultArticleMsid,
        defaultVideoMsid,
        defaultSlideshowMsid,
        defaultAuthorID,
        defaultStaticMsid,
        defaultQuizId,
        defaultSurveyId
      ];
      localStorage.setItem("defaultIds", JSON.stringify(defaultIds));
      localStorage.setItem("defaultKeywords", defaultKeywords);
      localStorage.setItem("defaultSystemHostId", defaultSystemHostId);
      localStorage.setItem("defaultListingMsid", defaultListingMsid);
      localStorage.setItem("defaultPriortyListMsid", defaultPriortyListMsid);
      localStorage.setItem("defaultArticleMsid", defaultArticleMsid);
      localStorage.setItem("defaultAuthorID", defaultAuthorID);

      componentJson = [
// ----------------------------theme-two-comoponents------------------------------------------------

        {
          name: "Ad Scripts Two",
          media: AddScriptIcon,
          traits: [{
            type: 'custom-textarea',
            label: 'Header Script',
            name: 'headAdScript',
            changeProp: 1,
            value: ''
          },
          {
            type: 'custom-textarea',
            label: 'Body Script',
            name: 'bodyAdScript',
            changeProp: 1,
            value: ''
          },
          {
            type: 'custom-textarea',
            label: 'Body Script (Mobile)',
            name: 'bodyAdMobileScript',
            changeProp: 1,
            value: ''
          },
          {
            type: 'custom-select',
            label: 'Ad Size',
            name: 'adSize',
            changeProp: 1,
            value: '300*100',
            options: [
              { label: '300*100', value: "300*100" },
              { label: '300*250', value: "300*250" },
              { label: '970*90', value: "970*90" },
            ]
          }],
          attributes: {
            title: "Add Scripts Two",
            version: "1000",
            adSize: "300*100",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            showAdSlot: true
          }
        },

        {
          name: "Article Show Two",
          traits: [
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'custom-toggle',
              label: 'Tags',
              name: 'showTags',
              changeProp: 1,
              options: [
                { id: 7, name: 'True', value: true },
                { id: 8, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'News Agency',
              name: 'showNewsAgency',
              changeProp: 1,
              options: [
                { id: 9, name: 'True', value: true },
                { id: 19, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Preview',
              name: 'isPreview',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              },
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ],
          media: ArticleShowIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "Article Show",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showAuthor: true,
            isPreview: false,
            showDateTime: true,
            showAdSlot: false,
            showTags: true,
            background: '#fff',
            showNewsAgency: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",
            adNetwork: "google",
            adSize: "1",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
            //GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                primary: true,
                isSSR: true,
                preview: false,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  msid: defaultArticleMsid,
                  hostId: defaultHostId,
                  fv: 1000,
                  cloneMapping: [
                    { from: "defaultArticleMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "Author Show Two",
          traits: [
            {
              type: 'custom-toggle',
              label: 'Department',
              name: 'showDepartment',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Social Icons',
              name: 'showSocialIcon',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ],
          media: AuthorShowIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "Author Show Two",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showSocialIcon: true,
            showDepartment: true,
            background: '#fff',
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            stylesheets: [],
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.AUTHOR_SHOW_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.AUTHOR_SHOW_API,
                primary: true,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  msid: defaultAuthorID,
                  fv: 1000,
                  hostId: defaultHostId,
                  cloneMapping: [
                    { from: "defaultAuthorID", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "BreadCrumbs Two",
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "BreadCrumbs",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            seperator: ">>",
            categoryName: "",
            TextColor: "#000",
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultArticleMsid,
                  apiType: 'nav',
                  cp: 1,
                  pp: 4,
                  cloneMapping: [
                    { from: "defaultArticleMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          media: BreadCrumbsIcon,
          traits: [
            {
              type: 'custom-select',
              label: 'Seperator',
              name: 'seperator',
              changeProp: 1,
              options: [
                { label: '>>', value: ">>" },
                { label: '>', value: ">" },
                { label: '/', value: "/" }
              ],
              value: ">>"
            },
            {
              type: 'text',
              name: 'categoryName',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Text Color",
              name: 'TextColor',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ]
        },
        {
          name: "Breaking News Ticker",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Heading BG Color",
              name: 'HeadingBGColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Heading Text Color",
              name: 'HeadingTextColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "BG Color",
              name: 'BGColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Text Color",
              name: 'TextColor',
              changeProp: 1
            }
          ],
          media: BreakingNewsIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: false,
            CONSTANTS: CONSTANTS,
            name: "Breaking News Ticker",
            title: "Breaking News Ticker",
            mainHeading: "Breaking News",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            HeadingBGColor: "#0A66C2",
            HeadingTextColor: "#fff",
            TextColor: "#fff",
            BgColor: "#000000",
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_TICKER_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_TICKER_API,
                isSSR: false,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  tenantId: tenantId,
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "Footer Two",
          traits: [
            {
              type: 'color',
              label: "BG Color",
              name: 'BgColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Text Color",
              name: 'TextColor',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'About Site',
              name: 'showAbout',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Social Icons',
              name: 'showSocialIcons',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'copyRightText',
              name: 'showSocialIcons',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Static Pages',
              name: 'showStaticPages',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Number of Items',
              name: 'noOfHeaderItems',
              changeProp: 1
            }
          ],
          media: FooterIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            BgColor: '#fff',
            TextColor: '#000',
            showAbout: true,
            showSocialIcons: true,
            copyRightText: "",
            footerLogo: "",
            title: "Footer",
            isSticky: true,
            showStaticPages: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            noOfHeaderItems:10,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },
              {
                name: "FOOTER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "userHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "GridView Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            // {
            //   type: 'color',
            //   label: "Background Color",
            //   name: 'BgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Card BG Color",
            //   name: 'cardBGColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Text Color",
            //   name: 'cardTextColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'custom-select',
            //   label: 'Scroll Mode',
            //   name: 'scroll',
            //   changeProp: 1,
            //   options: [
            //     { label: 'Automatic', value: "automatic" },
            //     { label: 'Manual', value: "manual" },
            //   ]
            // },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIconLess,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "GridView Two",
            title: "Grid View",
            mainHeading: "Grid View",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            BgColor: "#ffffff",
            cardBGColor: "#ffffff",
            cardTextColor: "#555555",
            scroll: "automatic",
            showDate: true,
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 12,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "Header Two",
          media: HeaderIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            stylesheets: [],
            title: "Header",
            showSearch: true,
            stylesheets: [],
            logoUrl: "https://www.newsclick.in/sites/default/files/2022-11/narendra%20%20modi.jpg",
            isSticky: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            mainHeadingColor: "#000",
            L1BgColor: "#ffffff",
            L2BgColor: "#f1f1f1",
            L1TextColor: "#555555",
            L2TextColor: "#555555",
            HamburgerBgColor: "#ffffff",
            HamburgerTextColor: "#555555",
            noOfHeaderItems: 6,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },

              {
                name: "HEADER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "userHostId", to: "hostId" }
                  ]
                }
              },

              {
                name: "TOGGLER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          traits: [
            {
              type: 'color',
              label: "L1 BG Color",
              name: 'L1BgColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "L1 Text Color",
              name: 'L1TextColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "L2 BG Color",
              name: 'L2BgColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "L2 Text Color",
              name: 'L2TextColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Hamburger BG Color",
              name: 'HamburgerBgColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Hamburger Text Color",
              name: 'HamburgerTextColor',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Search',
              name: 'showSearch',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },

            {
              type: 'custom-toggle',
              label: 'Sticky Header',
              name: 'isSticky',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Number of Items',
              name: 'noOfHeaderItems',
              changeProp: 1
            },
          ]
        },
        {
          name: "HeroLayout Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            // {
            //   type: 'color',
            //   label: "BG Color",
            //   name: 'BgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Text Color",
            //   name: 'cardTextColor',
            //   changeProp: 1
            // },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              showAuthor: true,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'True', value: true }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            // {
            //   type: 'custom-toggle',
            //   label: 'Date',
            //   name: 'showDate',
            //   changeProp: 1,
            //   showDate:true,
            //   options: [
            //     { id: 3, name: 'True', value: true },
            //     { id: 4, name: 'False', value: false }
            //   ],
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            // {
            //   type: 'custom-toggle',
            //   label: 'Ad Slot',
            //   name: 'showAdSlot',
            //   changeProp: 1,
            //   showAdSlotField: true,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            DATA_MAPPING_TRAIT
          ],
          media: HeroLayoutIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "Hero layout Two",
            title: "Hero layout Two",
            mainHeading: "Hero layout Two",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            cardTextColor: "#000",
            BgColor: "#f1f1f1",
            showAuthor: true,
            showDate: true,
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 10,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "HeroLayout Hr Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            // {
            //   type: 'color',
            //   label: "BG Color",
            //   name: 'BgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Card Text Color",
            //   name: 'cardTextColor',
            //   changeProp: 1
            // },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              showAuthor: true,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'True', value: true }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            // {
            //   type: 'custom-toggle',
            //   label: 'Date',
            //   name: 'showDate',
            //   changeProp: 1,
            //   showDate:true,
            //   options: [
            //     { id: 3, name: 'True', value: true },
            //     { id: 4, name: 'False', value: false }
            //   ],
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            // {
            //   type: 'custom-toggle',
            //   label: 'Ad Slot',
            //   name: 'showAdSlot',
            //   changeProp: 1,
            //   showAdSlotField: true,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            DATA_MAPPING_TRAIT
          ],
          media: HeroLayoutSecondIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "Hero layout Hr Two",
            title: "Hero layout Hr Two",
            mainHeading: "Hero layout Hr Two",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            // cardBGColor: "pink",
            cardBGColor: "transparent",
            cardActiveColor: "#ffffff",
            showAuthor: true,
            showDate: true,
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 10,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Home Grid View Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            // {
            //   type: 'color',
            //   label: "BG Color",
            //   name: 'BgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Card BG Color",
            //   name: 'cardBgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Text Color",
            //   name: 'cardTextColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'custom-select',
            //   label: 'Scroll Mode',
            //   name: 'scroll',
            //   changeProp: 1,
            //   options: [
            //     { label: 'Automatic', value: "automatic" },
            //     { label: 'Manual', value: "manual" },
            //   ]
            // },
            // {
            //   type: 'text',
            //   label: 'Scroll Count',
            //   name: 'scrollCount',
            //   changeProp: 1
            // },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              showDate: true,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            // {
            //   type: 'custom-toggle',
            //   label: 'Ad Slot',
            //   name: 'showAdSlot',
            //   changeProp: 1,
            //   showAdSlotField: true,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "Top News",
            title: "Top News",
            mainHeading: "Top News",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            BgColor: "#ffffff",
            cardBGColor: "#ffffff",
            cardTextColor: "#000",
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            showAuthor: true,
            showDate: true,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 9,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Quick View Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            // {
            //   type: 'color',
            //   label: "BG Color",
            //   name: 'cardBGColor',
            //   changeProp: 1
            // },

            {
              type: 'custom-select',
              label: 'Scroll Mode',
              name: 'scroll',
              changeProp: 1,
              options: [
                { label: 'Automatic', value: "automatic" },
                { label: 'Manual', value: "manual" },
              ]
            },
            {
              type: 'text',
              label: 'Scroll Count',
              name: 'scrollCount',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: BreakingNewsIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "Quick View",
            title: "Quick View",
            mainHeading: "Quick View",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            cardBGColor: "#fff",
            cardActiveColor: "#fff",
            showDateField: true,
            showAuthorField: true,
            showDate: true,
            showAuthor: true,
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 10,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "List View Two",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            // {
            //   type: 'color',
            //   label: "Card Color",
            //   name: 'cardColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "BG Color",
            //   name: 'cardBgColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'color',
            //   label: "Card Text Color",
            //   name: 'cardTextColor',
            //   changeProp: 1
            // },
            // {
            //   type: 'custom-select',
            //   label: 'Scroll Mode',
            //   name: 'scroll',
            //   changeProp: 1,
            //   options: [
            //     { label: 'Automatic', value: "automatic" },
            //     { label: 'Manual', value: "manual" },
            //   ]
            // },
            // {
            //   type: 'text',
            //   label: 'Scroll Count',
            //   name: 'scrollCount',
            //   changeProp: 1
            // },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,

              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              showDate: true,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: ListViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "List View Two",
            title: "List View",
            mainHeading: "List View",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            cardTextColor: "#000",
            // cardBgColor: "#f1f1f1",
            cardColor: "transparent",
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            showAuthor: true,
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 10,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                loading: "LAZY",
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultListingMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "MediaList Two",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              showDate: true,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Media List",
            name: "MediaList Two",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showDateTime: true,
            mainHeadingColor: '#000',
            cardTextColor: '#000',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 20,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                loading: "LAZY",
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultListingMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        
{
  name: "Page Not Found Two",
  traits: [
  ],
  media: ErrorPageIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: "This is the Default title",
    version: "1000",
    showDateTime: true
  }
},
        {
          name: "PhotoList Two",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Title Text Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDate',
              changeProp: 1,
              showDate: true,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              showAuthor: true,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            // {
            //   type: 'custom-toggle',
            //   label: 'View All',
            //   name: 'showViewAll',
            //   changeProp: 1,
            //   options: [
            //     { id: "ddd", name: 'True', value: true },
            //     { id: 1, name: 'False', value: false }
            //   ],
            //   showViewURLField: true,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            // {
            //   type: 'custom-toggle',
            //   label: 'Viewport',
            //   name: 'showViewport',
            //   changeProp: 1,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // },
            // {
            //   type: 'text',
            //   label: 'Images In Viewport',
            //   name: 'imagesInViewport',
            //   changeProp: 1,
            //   value: 'all'
            // },

            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot2',
              name: 'showAdSlot2',
              changeProp: 1,
              showAdSlotField2: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Photo List",
            name: "PhotoList Two",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthor: true,
            showDate: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            showAdSlot2: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            adNetwork2: "google",
            headAdScript2: "",
            bodyAdScript2: "",
            bodyAdMobileScript2: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 20,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                prime: true,
                loading: "LAZY",
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultListingMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
{
  name: "Podcast Two",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthorName',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Tags',
      name: 'showTags',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showTimeStamp',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }

  ],
  media: VideoStoriesIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: 'Video Show',
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showTags: true,
    showAuthorName: true,
    showTimeStamp: true,
    showNewsAgency: true,
    onClick: () => {
      var settingBtn = document.querySelectorAll('[title="Component Properties"]');
      settingBtn[0].click();
    },
    stylesheets: [],
    ENV: CONSTANTS.ENV.M360_STAGING,
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.VIDEO_SHOW_API,
        primary: true,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultVideoMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 4,
          cloneMapping: [
            { from: "defaultVideoMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "RhsHome Two",
  traits: [
    // {
    //   type: 'text',
    //   label: "Title",
    //   name: 'mainHeading',
    //   changeProp: 1
    // },
    {
      type: 'text',
      label: "Title URL",
      name: 'mainHeadingHref',
      changeProp: 1
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    // {
    //   type: 'color',
    //   label: "Text Color",
    //   name: 'cardContentColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'color',
    //   label: "BG Color",
    //   name: 'cardBGColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'color',
    //   label: "Title Text Color",
    //   name: 'mainHeadingColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'custom-toggle',
    //   label: 'Ad Slot',
    //   name: 'showAdSlot',
    //   changeProp: 1,
    //   showAdSlotField: true,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    // {
    //   type: 'custom-toggle',
    //   label: 'Author',
    //   name: 'showAuthorName',
    //   changeProp: 1,
    //   showAuthorName:true,
    //   options: [
    //     { id: 3, name: 'True', value: true },
    //     { id: 4, name: 'False', value: false }
    //   ],
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    // {
    //   type: 'custom-toggle',
    //   label: 'Heading',
    //   name: 'showHeading',
    //   changeProp: 1,
    //   showHeading:false,
    //   options: [
    //     { id: 3, name: 'True', value: true },
    //     { id: 4, name: 'False', value: false }
    //   ],
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    DATA_MAPPING_TRAIT
  ],
  media: ListViewIcon,
  attributes: {
    STATIC_MAPPING_TRAIT: true,
    CONSTANTS: CONSTANTS,
    name: "Home Related News",
    title: "Home Related News",
    mainHeading: "Home Related News",
    version: "1000",
    mainHeadingHref: "https://www.google.com",
    mainHeadingColor: "#000",
    cardContentColor: "#000",
    cardBGColor: "transparent",
    scroll: "automatic",
    scrollCount: 1,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showAdSlot: false,
    showAuthorName: false,
    showHeading: false,
    adNetwork: "google",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultPriortyListMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 7,
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "RhsHome Other Two",
  traits: [
    {
      type: 'text',
      label: "Title",
      name: 'mainHeading',
      changeProp: 1
    },
    {
      type: 'text',
      label: "Title URL",
      name: 'mainHeadingHref',
      changeProp: 1
    },
    // {
    //   type: 'color',
    //   label: "Text Color",
    //   name: 'cardContentColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'color',
    //   label: "BG Color",
    //   name: 'cardBGColor',
    //   changeProp: 1
    // },
    {
      type: 'color',
      label: "Title Text Color",
      name: 'mainHeadingColor',
      changeProp: 1
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthorName',
      changeProp: 1,

      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Heading',
      name: 'showHeading',
      changeProp: 1,
      showHeading: false,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    DATA_MAPPING_TRAIT
  ],
  media: ListViewIcon,
  attributes: {
    STATIC_MAPPING_TRAIT: true,
    CONSTANTS: CONSTANTS,
    name: "Related News",
    title: "Related News",
    mainHeading: "Related News",
    version: "1000",
    mainHeadingHref: "https://www.google.com",
    mainHeadingColor: "#000",
    cardContentColor: "#000",
    cardBGColor: "transparent",
    scroll: "automatic",
    scrollCount: 1,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showAdSlot: false,
    showAuthorName: true,
    showHeading: true,
    adNetwork: "google",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultPriortyListMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 7,
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{

  name: "Slide Show Two",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 5, name: 'True', value: true },
        { id: 6, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'text',
      label: 'Images In Viewport',
      name: 'imagesInViewport',
      changeProp: 1,
      value: 'all'
    },
    {
      type: 'custom-toggle',
      label: 'Preview',
      name: 'isPreview',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      },
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
  ],
  media: SlideShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: 'Slide Show',
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    showDateTime: true,
    isPreview: false,
    showNewsAgency: true,
    background: '#fff',
    showAdSlot: false,
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENV: CONSTANTS.ENV.M360_STAGING,
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    showViewport: true,
    imagesInViewport: "all",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
        STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
        isSSR: true,
        SSRDataObj: null,
        preview: false,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultSlideshowMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          cloneMapping: [
            { from: "defaultSlideshowMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      },
      {
        name: "SEO_URL",
        LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        primary: true,
        loading: "LAZY",
        isSSR: true,
        preview: false,
        prime: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: userHostId,
          msid: defaultSlideshowMsid,
          apiType: "d",
          cloneMapping: [
            { from: "defaultSlideshowMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},




{
  name: "Static Page Two",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Title',
      name: 'showTitle',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
  ],
  media: StaticPageIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    background: '#fff',
    version: "1000",
    title: "Static Page Two",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showTitle: true,
    ENV: CONSTANTS.ENV.M360_STAGING,
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        primary: true,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          msid: defaultStaticMsid,
          hostId: defaultHostId,
          fv: 1000,
          cloneMapping: [
            { from: "defaultStaticMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ],
        }
      }
    ]
  }
},

{
  name: "Breaking News Two",
  traits: [
    {
      type: 'text',
      label: "Title",
      name: 'mainHeading',
      changeProp: 1
    },
    {
      type: 'text',
      label: "Title URL",
      name: 'mainHeadingHref',
      changeProp: 1
    },
    {
      type: 'color',
      label: "Title Text Color",
      name: 'mainHeadingColor',
      changeProp: 1
    },
    {
      type: 'color',
      label: "BG Color",
      name: 'BGColor',
      changeProp: 1
    },
    {
      type: 'color',
      label: "Content Color",
      name: 'cardContentColor',
      changeProp: 1
    },
    {
      type: 'color',
      label: "Card BG Color",
      name: 'cardBGColor',
      changeProp: 1
    },
    {
      type: 'custom-select',
      label: 'Scroll Mode',
      name: 'scroll',
      changeProp: 1,
      options: [
        { label: 'Automatic', value: "automatic" },
        { label: 'Manual', value: "manual" },
      ]
    },
    {
      type: 'text',
      label: 'Scroll Count',
      name: 'scrollCount',
      changeProp: 1
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    // {
    //   type: 'custom-toggle',
    //   label: 'Ad Slot',
    //   name: 'showAdSlot',
    //   changeProp: 1,
    //   showAdSlotField: true,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    DATA_MAPPING_TRAIT
  ],
  media: BreakingNewsIcon,
  attributes: {
    STATIC_MAPPING_TRAIT: true,
    CONSTANTS: CONSTANTS,
    name: "Trending News",
    title: "Trending News",
    mainHeading: "Trending News",
    version: "1000",
    mainHeadingHref: "https://www.google.com",
    mainHeadingColor: "#000",
    // cardBGColor: "pink",
    cardBGColor: "#fff",
    cardActiveColor: "#fff",
    scroll: "automatic",
    scrollCount: 1,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showAdSlot: false,
    adNetwork: "google",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultPriortyListMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 10,
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},

{
  name: "Video Show Two",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthorName',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Tags',
      name: 'showTags',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showTimeStamp',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }

  ],
  media: VideoStoriesIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: 'Video Show',
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showTags: true,
    showAuthorName: true,
    showTimeStamp: true,
    showNewsAgency: true,
    onClick: () => {
      var settingBtn = document.querySelectorAll('[title="Component Properties"]');
      settingBtn[0].click();
    },
    stylesheets: [],
    ENV: CONSTANTS.ENV.M360_STAGING,
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.VIDEO_SHOW_API,
        primary: true,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultVideoMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 4,
          cloneMapping: [
            { from: "defaultVideoMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "WebStories Two",
  traits: [
    {
      type: 'text',
      label: "Title",
      name: 'mainHeading',
      changeProp: 1
    },
    {
      type: 'text',
      label: "Title URL",
      name: 'mainHeadingHref',
      changeProp: 1
    },
    {
      type: 'color',
      label: "Title Text Color",
      name: 'mainHeadingColor',
      changeProp: 1
    },
    {
      type: 'color',
      label: "BG Color",
      name: 'cardBGColor',
      changeProp: 1
    },
    // {
    //   type: 'color',
    //   label: "Card Color",
    //   name: 'cardActiveColor',
    //   changeProp: 1
    // },
    {
      type: 'custom-select',
      label: 'Scroll Mode',
      name: 'scroll',
      changeProp: 1,
      options: [
        { label: 'Automatic', value: "automatic" },
        { label: 'Manual', value: "manual" },
      ]
    },
    {
      type: 'text',
      label: 'Scroll Count',
      name: 'scrollCount',
      changeProp: 1
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDate',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    // {
    //   type: 'custom-toggle',
    //   label: 'Ad Slot',
    //   name: 'showAdSlot',
    //   changeProp: 1,
    //   showAdSlotField: true,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    DATA_MAPPING_TRAIT
  ],
  media: CarouselCardIcon,
  attributes: {
    STATIC_MAPPING_TRAIT: true,
    CONSTANTS: CONSTANTS,
    name: "Web Stories",
    title: "Web Stories",
    mainHeading: "Web Stories",
    version: "1000",
    mainHeadingHref: "https://www.google.com",
    mainHeadingColor: "#000",
    showDate: true,
    // cardBGColor: "pink",
    cardBGColor: "#EFEFEF",
    cardActiveColor: "#ffffff",
    scroll: "automatic",
    scrollCount: 1,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showAdSlot: false,
    adNetwork: "google",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultPriortyListMsid,
          apiType: "h",
          ct: 'webstory',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 10,
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "WebStoriesList Two",
  traits: [
    {
      type: 'text',
      label: "Title",
      name: 'mainHeading',
      changeProp: 1
    },
    {
      type: 'text',
      label: "Title URL",
      name: 'mainHeadingHref',
      changeProp: 1
    },
    {
      type: 'color',
      label: "Title Text Color",
      name: 'mainHeadingColor',
      changeProp: 1
    },
    // {
    //   type: 'custom-toggle',
    //   label: 'Author',
    //   name: 'showAuthor',
    //   changeProp: 1,
    //   showAuthor:true,
    //   options: [
    //     { id: 3, name: 'True', value: true },
    //     { id: 4, name: 'False', value: false }
    //   ],
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDate',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    // {
    //   type: 'color',
    //   label: "Background Color",
    //   name: 'BgColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'color',
    //   label: "Card BG Color",
    //   name: 'cardBGColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'color',
    //   label: "Text Color",
    //   name: 'cardTextColor',
    //   changeProp: 1
    // },
    // {
    //   type: 'custom-select',
    //   label: 'Scroll Mode',
    //   name: 'scroll',
    //   changeProp: 1,
    //   options: [
    //     { label: 'Automatic', value: "automatic" },
    //     { label: 'Manual', value: "manual" },
    //   ]
    // },
    {
      type: 'custom-select',
      label: 'Select Type',
      name: 'type1',
      changeProp: 1,
      options: [
        { label: 'Pagination', value: "page" },
        { label: 'Next/Prev', value: "next" },
        { label: 'Load More', value: "load" },
        { label: 'No Pagination', value: "noPagination" }
      ],
      value: "page"
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },

    // {
    //   type: 'custom-toggle',
    //   label: 'Ad Slot',
    //   name: 'showAdSlot',
    //   changeProp: 1,
    //   showAdSlotField: true,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
    DATA_MAPPING_TRAIT
  ],
  media: GridViewIcon,
  attributes: {
    STATIC_MAPPING_TRAIT: true,
    CONSTANTS: CONSTANTS,
    name: "Web Stories List",
    title: "Web Stories List",
    mainHeading: "Web Stories List",
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    mainHeadingHref: "https://www.google.com",
    mainHeadingColor: "#000",
    BgColor: "#ffffff",
    cardBgColor: "transparent",
    cardTextColor: "#555555",
    scroll: "automatic",
    showDate: true,
    scrollCount: 1,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showAdSlot: false,
    adNetwork: "google",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultPriortyListMsid,
          apiType: "h",
          ct: 'webstory',
          st: 'date',
          so: 'dsc',
          cp: 1,
          pp: 20,
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      },
      {
        name: "SEO_URL",
        LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        primary: true,
        loading: "LAZY",
        isSSR: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: userHostId,
          msid: defaultPriortyListMsid,
          apiType: "d",
          cloneMapping: [
            { from: "defaultPriortyListMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},


// -------------------------End---theme-two-comoponents------------------------------------------------
// ----------------------------theme-two-amp-comoponents------------------------------------------------
{
  name: "Article Show Two Amp",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Tags',
      name: 'showTags',
      changeProp: 1,
      options: [
        { id: 7, name: 'True', value: true },
        { id: 8, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 9, name: 'True', value: true },
        { id: 19, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Preview',
      name: 'isPreview',
      isNotChecked: function () {
        const context = this;
        return !context.value;
      },
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
    }
    // {
    //   type: 'custom-toggle',
    //   label: 'Open In New Tab',
    //   name: 'anchorLinksValue',
    //   changeProp: 1,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // }
  ],
  media: ArticleShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: "Article Show",
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    showDateTime: true,
    showAdSlot: false,
    isPreview: false,
    showTags: true,
    background: '#fff',
    showNewsAgency: true,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showViewport: true,
    imagesInViewport: "all",
    adNetwork: "google",
    adSize: "1",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",
    // GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        primary: true,
        isSSR: true,
        preview: false,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          msid: defaultArticleMsid,
          hostId: defaultHostId,
          fv: 1000,
          cloneMapping: [
            { from: "defaultArticleMsid", to: "msid" }
          ]
        }
      }
    ]
  }
},
{
  name: "Footer Two Amp",
  traits: [
    {
      type: 'custom-toggle',
      label: 'About Site',
      name: 'showAbout',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Social Icons',
      name: 'showSocialIcons',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'text',
      label: 'copyRightText',
      name: 'showSocialIcons',
      changeProp: 1
    },
    {
      type: 'custom-toggle',
      label: 'Static Pages',
      name: 'showStaticPages',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
  ],
  media: FooterIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    background: '#fff',
    showAbout: true,
    showSocialIcons: true,
    copyRightText: "",
    footerLogo: "",
    title: "Footer",
    isSticky: true,
    showStaticPages: true,
    ENV: CONSTANTS.ENV.M360_STAGING,

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          websiteId: retrievedWebsiteId,
          clientId: retrievedClientId
        }
      },
      {
        name: "FOOTER_MENU_API",
        LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          hostId: userHostId
        }
      }
    ]
  }
},
{
  name: "Header Two Amp",
  media: HeaderIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    background: '#fff',
    stylesheets: [],
    title: "Header",
    showSearch: true,
    stylesheets: [],
    ENV: CONSTANTS.ENV.M360_STAGING,

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          websiteId: retrievedWebsiteId,
          clientId: retrievedClientId
        }
      },

      {
        name: "HEADER_MENU_API",
        LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          hostId: userHostId
        }
      },

      {
        name: "TOGGLER_MENU_API",
        LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          hostId: userHostId
        }
      }
    ]
  },
  traits: [
    {
      type: 'custom-toggle',
      label: 'Search',
      name: 'showSearch',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
    // {
    //   type: 'custom-toggle',
    //   label: 'Open In New Tab',
    //   name: 'anchorLinksValue',
    //   changeProp: 1,
    //   isNotChecked: function () {
    //     const context = this;
    //     return !context.value;
    //   }
    // },
  ]

},
{
  name: "Photo Show Two Amp",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 5, name: 'True', value: true },
        { id: 6, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'text',
      label: 'Images In Viewport',
      name: 'imagesInViewport',
      changeProp: 1,
      value: 'all'
    },
    {
      type: 'custom-toggle',
      label: 'Preview',
      name: 'isPreview',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
  ],
  media: SlideShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: 'Photo Show Two Amp',
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    showDateTime: true,
    isPreview: false,
    showNewsAgency: true,
    showAdSlot: false,
    background: '#fff',
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENV: CONSTANTS.ENV.M360_STAGING,
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    showViewport: true,
    imagesInViewport: "all",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
        STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
        isSSR: true,
        SSRDataObj: null,
        preview: false,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultSlideshowMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          cloneMapping: [
            { from: "defaultSlideshowMsid", to: "msid" }
          ]
        }
      },
      {
        name: "SEO_URL",
        LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
        primary: true,
        loading: "LAZY",
        isSSR: true,
        preview: false,
        prime: true,
        SSRDataObj: null,
        PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        queryParams: {
          fv: 1000,
          hostId: userHostId,
          msid: defaultSlideshowMsid,
          apiType: "d",
          cloneMapping: [
            { from: "defaultSlideshowMsid", to: "msid" }
          ]
        }
      }
    ]
  }
},
{
  name: "Video Show Two Amp",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 5, name: 'True', value: true },
        { id: 6, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'text',
      label: 'Images In Viewport',
      name: 'imagesInViewport',
      changeProp: 1,
      value: 'all'
    }
  ],
  media: SlideShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: 'Video Show Two Amp',
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    showDateTime: true,
    showNewsAgency: true,
    showAdSlot: false,
    background: '#fff',
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENV: CONSTANTS.ENV.M360_STAGING,
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    showViewport: true,
    imagesInViewport: "all",

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
        STAG_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
        isSSR: true,
        SSRDataObj: null,
        queryParams: {
          fv: 1000,
          hostId: defaultHostId,
          msid: defaultVideoMsid,
          apiType: "h",
          ct: 'all',
          st: 'date',
          so: 'dsc',
          cp: 1,
          cloneMapping: [
            { from: "defaultVideoMsid", to: "msid" }
          ]
        }
      }
    ]
  }
},

// -------------------------End---theme-two-amp-comoponents------------------------------------------------
// ----------------------------theme-one-comoponents------------------------------------------------
 
{
  name: "Ad Scripts New",
  media: AddScriptIcon,
  traits: [{
    type: 'custom-textarea',
    label: 'Header Script',
    name: 'headAdScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-textarea',
    label: 'Body Script',
    name: 'bodyAdScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-textarea',
    label: 'Body Script (Mobile)',
    name: 'bodyAdMobileScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-select',
    label: 'Ad Size',
    name: 'adSize',
    changeProp: 1,
    value: '300*100',
    options: [
      { label: '300*100', value: "300*100" },
      { label: '300*250', value: "300*250" },
      { label: '970*90', value: "970*90" },
    ]
  }],
  attributes: {
    title: "Ad Scripts",
    version: "1000",
    adSize: "300*100",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",
    showAdSlot: true
  }
},
{
  name: "Ad Scripts Amp",
  media: AddScriptIcon,
  traits: [{
    type: 'custom-textarea',
    label: 'Header Script',
    name: 'headAdScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-textarea',
    label: 'Body Script',
    name: 'bodyAdScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-textarea',
    label: 'Body Script (Mobile)',
    name: 'bodyAdMobileScript',
    changeProp: 1,
    value: ''
  },
  {
    type: 'custom-select',
    label: 'Ad Size',
    name: 'adSize',
    changeProp: 1,
    value: '300*100',
    options: [
      { label: '300*100', value: "300*100" },
      { label: '300*250', value: "300*250" },
    ]
  }],
  attributes: {
    title: "Add Scripts",
    version: "1000",
    adSize: "300*100",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",
    showAdSlot: true
  }
},

{
  name: "Article Show",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'text',
      label: 'Images In Viewport',
      name: 'imagesInViewport',
      changeProp: 1,
      value: 'all'
    },
    {
      type: 'custom-toggle',
      label: 'Tags',
      name: 'showTags',
      changeProp: 1,
      options: [
        { id: 7, name: 'True', value: true },
        { id: 8, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 9, name: 'True', value: true },
        { id: 19, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Preview',
      name: 'isPreview',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Open In New Tab',
      name: 'anchorLinksValue',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
  ],
  media: ArticleShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: "Article Show",
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    isPreview: false,
    showDateTime: true,
    showAdSlot: false,
    showTags: true,
    background: '#fff',
    showNewsAgency: true,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showViewport: true,
    imagesInViewport: "all",
    adNetwork: "google",
    adSize: "1",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",
    // GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        primary: true,
        isSSR: true,
        preview: false,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          msid: defaultArticleMsid,
          hostId: defaultHostId,
          fv: 1000,
          cloneMapping: [
            { from: "defaultArticleMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "Article Show Amp",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Author',
      name: 'showAuthor',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Date',
      name: 'showDateTime',
      changeProp: 1,
      options: [
        { id: 3, name: 'True', value: true },
        { id: 4, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Tags',
      name: 'showTags',
      changeProp: 1,
      options: [
        { id: 7, name: 'True', value: true },
        { id: 8, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Ad Slot',
      name: 'showAdSlot',
      changeProp: 1,
      showAdSlotField: true,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'News Agency',
      name: 'showNewsAgency',
      changeProp: 1,
      options: [
        { id: 9, name: 'True', value: true },
        { id: 19, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Preview',
      name: 'isPreview',
      changeProp: 1,
      options: [
        { id: 1, name: 'True', value: true },
        { id: 2, name: 'False', value: false }
      ],
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
  ],
  media: ArticleShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: "Article Show",
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showAuthor: true,
    showDateTime: true,
    isPreview: false,
    showAdSlot: false,
    showTags: true,
    background: '#fff',
    showNewsAgency: true,
    ENV: CONSTANTS.ENV.M360_STAGING,
    showViewport: true,
    imagesInViewport: "all",
    adNetwork: "google",
    adSize: "1",
    headAdScript: "",
    bodyAdScript: "",
    bodyAdMobileScript: "",
    GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
    //GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },

    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
        primary: true,
        isSSR: true,
        preview: false,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          msid: defaultArticleMsid,
          hostId: defaultHostId,
          fv: 1000,
          cloneMapping: [
            { from: "defaultArticleMsid", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},
{
  name: "Author Show",
  traits: [
    {
      type: 'custom-toggle',
      label: 'Department',
      name: 'showDepartment',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Social Icons',
      name: 'showSocialIcon',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    },
    {
      type: 'custom-toggle',
      label: 'Viewport',
      name: 'showViewport',
      changeProp: 1,
      isNotChecked: function () {
        const context = this;
        return !context.value;
      }
    }
  ],
  media: AuthorShowIcon,
  attributes: {
    CONSTANTS: CONSTANTS.API_URL,
    title: "Author Show",
    version: "1000",
    EXPORTED_DATA: {
      SEO_DATA: null
    },
    showSocialIcon: true,
    showDepartment: true,
    background: '#fff',
    onClick: () => {
      var settingBtn = document.querySelectorAll('[title="Component Properties"]');
      settingBtn[0].click();
    },
    mappings: {
      type: "pathquery",
      requestKey: 'msid',
      value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
    },
    stylesheets: [],
    ENV: CONSTANTS.ENV.M360_STAGING,
    showViewport: true,

    ENDPOINTS: [
      {
        name: "MAIN",
        LIVE_API_URL: RENDERING_ENDPOINTS.AUTHOR_SHOW_API,
        STAG_API_URL: PAGE_BUILDER_ENDPOINTS.AUTHOR_SHOW_API,
        primary: true,
        isSSR: true,
        SSRDataObj: null,
        isNotListing: true,
        queryParams: {
          msid: defaultAuthorID,
          fv: 1000,
          hostId: defaultHostId,
          cloneMapping: [
            { from: "defaultAuthorID", to: "msid" },
            { from: "defaultHostId", to: "hostId" }
          ]
        }
      }
    ]
  }
},

        {
          name: "Breaking News",
          traits: [
            {
              type: 'text',
              label: "Title",
              name: 'mainHeading',
              changeProp: 1
            },
            {
              type: 'text',
              label: "Title URL",
              name: 'mainHeadingHref',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Card Content Color",
              name: 'mainHeadingColor',
              changeProp: 1
            },
            {
              type: 'color',
              label: "Card BG Color",
              name: 'cardBGColor',
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Scroll Mode',
              name: 'scroll',
              changeProp: 1,
              options: [
                { label: 'Automatic', value: "automatic" },
                { label: 'Manual', value: "manual" },
              ]
            },
            {
              type: 'text',
              label: 'Scroll Count',
              name: 'scrollCount',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: BreakingNewsIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS,
            name: "Breaking News",
            title: "Breaking News",
            mainHeading: "Breaking News",
            version: "1000",
            mainHeadingHref: "https://www.google.com",
            mainHeadingColor: "#000",
            // cardBGColor: "pink",
            cardBGColor: "",
            scroll: "automatic",
            scrollCount: 1,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultPriortyListMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 10,
                  cloneMapping: [
                    { from: "defaultPriortyListMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },



        {
          name: "Bread Crumbs",
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "BreadCrumbs",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            seperator: ">>",
            categoryName: "",
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultArticleMsid,
                  apiType: 'nav',
                  cp: 1,
                  pp: 4,
                  cloneMapping: [
                    { from: "defaultArticleMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          media: BreadCrumbsIcon,
          traits: [
            {
              type: 'custom-select',
              label: 'Seperator',
              name: 'seperator',
              changeProp: 1,
              options: [
                { label: '>>', value: ">>" },
                { label: '>', value: ">" },
                { label: '/', value: "/" }
              ],
              value: ">>"
            },
            {
              type: 'text',
              name: 'categoryName',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ]
        },

        {
          name: "Bread Crumbs Amp",
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "BreadCrumbs",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            seperator: ">>",
            categoryName: "",
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultArticleMsid,
                  apiType: 'nav',
                  cp: 1,
                  pp: 4,
                  cloneMapping: [
                    { from: "defaultArticleMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          media: BreadCrumbsIcon,
          traits: [
            {
              type: 'custom-select',
              label: 'Seperator',
              name: 'seperator',
              changeProp: 1,
              options: [
                { label: '>>', value: ">>" },
                { label: '>', value: ">" },
                { label: '/', value: "/" }
              ],
              value: ">>"
            },
            {
              type: 'text',
              name: 'categoryName',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ]
        },

        {
          name: "Carousel Card Block",
          media: CarouselCardIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Section Title",
            version: "1000",
            name: "Carousel Card",
            showDateTime: true,
            scrollMode: "automatic",
            scrollCount: 1,
            background: '#fff',
            stylesheets: [],
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            isCarousel: true,
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            viewAllUrl: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 27,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'text',
              label: 'Scroll Count',
              name: 'scrollCount',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
        },
        {
          name: "Circular Card Carousel",
          media: CarouselCardIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            // CONSTANTS: CONSTANTS.API_URL,
            CONSTANTS: CONSTANTS,
            title: "Section Title",
            version: "1000",
            scrollMode: "automatic",
            scrollCount: 1,
            background: '#fff',
            stylesheets: [],
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showViewAll: false,
            isCarousel: true,
            isCircular: true,
            viewAllUrl: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  authorid: defaultAuthorID,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 27,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultAuthorID", to: "authorid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Scroll Mode',
              name: 'scrollMode',
              changeProp: 1,
              options: [
                { label: 'Automatic', value: "automatic" },
                { label: 'Manual', value: "manual" },
              ]
            },
            {
              type: 'text',
              label: 'Scroll Count',
              name: 'scrollCount',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ]
        },
        {
          name: "Footer",
          traits: [
            {
              type: 'custom-toggle',
              label: 'About Site',
              name: 'showAbout',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Social Icons',
              name: 'showSocialIcons',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'copyRightText',
              name: 'showSocialIcons',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Static Pages',
              name: 'showStaticPages',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Number of Items',
              name: 'noOfHeaderItems',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ],
          media: FooterIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            showAbout: true,
            showSocialIcons: true,
            copyRightText: "",
            footerLogo: "",
            title: "Footer",
            isSticky: true,
            showStaticPages: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            noOfHeaderItems:12,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },
              {
                name: "FOOTER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Footer Amp",
          traits: [
            {
              type: 'custom-toggle',
              label: 'About Site',
              name: 'showAbout',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Social Icons',
              name: 'showSocialIcons',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'copyRightText',
              name: 'showSocialIcons',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Static Pages',
              name: 'showStaticPages',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
            // {
            //   type: 'custom-toggle',
            //   label: 'Open In New Tab',
            //   name: 'anchorLinksValue',
            //   changeProp: 1,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // }
          ],
          media: FooterIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            showAbout: true,
            showSocialIcons: true,
            copyRightText: "",
            footerLogo: "",
            title: "Footer",
            isSticky: true,
            showStaticPages: true,
            ENV: CONSTANTS.ENV.M360_STAGING,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },
              {
                name: "FOOTER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Grid View",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              // value: "page"   //This is making default pagination value
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIconLess,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Quick Views",
            name: "Grid View",
            version: "1000",
            showDateTime: true,
            background: '#fff',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 4,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Grid View2",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              // value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Quick Views",
            name: "Grid View2",
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showDateTime: true,
            background: '#fff',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 12,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                loading: "LAZY",
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultListingMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Header",
          media: HeaderIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            stylesheets: [],
            title: "Header",
            showSearch: true,
            stylesheets: [],
            logoUrl: "https://www.newsclick.in/sites/default/files/2022-11/narendra%20%20modi.jpg",
            isSticky: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            noOfHeaderItems: 9,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },

              {
                name: "HEADER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },

              {
                name: "TOGGLER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          traits: [
            {
              type: 'custom-toggle',
              label: 'Search',
              name: 'showSearch',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },

            {
              type: 'custom-toggle',
              label: 'Sticky Header',
              name: 'isSticky',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Number of Items',
              name: 'noOfHeaderItems',
              changeProp: 1
            },
          ]

        },

        {
          name: "Header Amp",
          media: HeaderIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            background: '#fff',
            stylesheets: [],
            title: "Header",
            showSearch: true,
            stylesheets: [],
            ENV: CONSTANTS.ENV.M360_STAGING,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  websiteId: retrievedWebsiteId,
                  clientId: retrievedClientId,
                  cloneMapping: [
                    { from: "retrievedWebsiteId", to: "websiteId" },
                    { from: "retrievedClientId", to: "clientId" }
                  ]
                }
              },

              {
                name: "HEADER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },

              {
                name: "TOGGLER_MENU_API",
                LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  hostId: userHostId,
                  cloneMapping: [
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          },
          traits: [
            {
              type: 'custom-toggle',
              label: 'Search',
              name: 'showSearch',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            // {
            //   type: 'custom-toggle',
            //   label: 'Open In New Tab',
            //   name: 'anchorLinksValue',
            //   changeProp: 1,
            //   isNotChecked: function () {
            //     const context = this;
            //     return !context.value;
            //   }
            // }
          ]

        },

        {
          name: "Hero Layout",
          traits: [

            {
              type: 'text',
              label: 'Title',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Time Stamp',
              name: 'showTimeStamp',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: HeroLayoutIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            background: '#fff',
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            stylesheets: [],
            showAuthorName: true,
            title: "Trending Today",
            name: "Hero Layout",
            showTimeStamp: true,
            showViewAll: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            viewAllUrl: "",
            showAdSlot: false,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 5,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "Hero Layout2",
          traits: [

            {
              type: 'text',
              label: 'Title',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Time Stamp',
              name: 'showTimeStamp',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: HeroLayoutSecondIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            version: "1000",
            background: '#fff',
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            stylesheets: [],
            showAuthorName: true,
            title: "Trending Today",
            name: "Hero Layout2",
            showTimeStamp: true,
            showViewAll: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            viewAllUrl: "",
            showAdSlot: false,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 9,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "List View",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Default Pagination', value: "page" },
                { label: 'Next Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: ListViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "This is the Default title",
            version: "1000",
            name: "List View",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showDateTime: true,
            type1: "page",
            background: '#fff',
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            stylesheets: [],
            showAuthorName: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                isListView: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 7,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                loading: "LAZY",
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultListingMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Page Not Found",
          traits: [
          ],
          media: ErrorPageIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: "This is the Default title",
            version: "1000",
            showDateTime: true
          }
        },

        {
          name: "Similar Stories",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Ad Slot',
              name: 'showAdSlot',
              changeProp: 1,
              showAdSlotField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: SimilarStoriesIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "This is the Default title",
            name: "Similar Stories",
            version: "1000",
            showDateTime: true,
            background: '#fff',
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            stylesheets: [],
            showAdSlot: false,
            adNetwork: "google",
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            showAuthorName: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",
            isSimilarStories: true,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 6,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {

          name: "Slide Show",
          traits: [
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthor',
              changeProp: 1,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'News Agency',
              name: 'showNewsAgency',
              changeProp: 1,
              options: [
                { id: 5, name: 'True', value: true },
                { id: 6, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Viewport',
              name: 'showViewport',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'text',
              label: 'Images In Viewport',
              name: 'imagesInViewport',
              changeProp: 1,
              value: 'all'
            },
            {
              type: 'custom-toggle',
              label: 'Preview',
              name: 'isPreview',
              changeProp: 1,
              options: [
                { id: 1, name: 'True', value: true },
                { id: 2, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
          ],
          media: SlideShowIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: 'Slide Show',
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showAuthor: true,
            showDateTime: true,
            isPreview: false,
            showNewsAgency: true,
            background: '#fff',
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENV: CONSTANTS.ENV.M360_STAGING,
            showViewport: true,
            imagesInViewport: "all",

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                preview: false,
                SSRDataObj: null,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultSlideshowMsid,
                  apiType: "h",
                  preview: false,
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  cloneMapping: [
                    { from: "defaultSlideshowMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              },
              {
                name: "SEO_URL",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                primary: true,
                loading: "LAZY",
                isSSR: true,
                preview: false,
                prime: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: userHostId,
                  msid: defaultSlideshowMsid,
                  apiType: "d",
                  cloneMapping: [
                    { from: "defaultSlideshowMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Static Page",
          traits: [
            {
              type: 'custom-toggle',
              label: 'Title',
              name: 'showTitle',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }
          ],
          media: StaticPageIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            background: '#fff',
            version: "1000",
            title: "Static Page",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showTitle: true,
            ENV: CONSTANTS.ENV.M360_STAGING,
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                primary: true,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  msid: defaultStaticMsid,
                  hostId: defaultHostId,
                  fv: 1000,
                  cloneMapping: [
                    { from: "defaultStaticMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ],
                }
              }
            ]
          }
        },

        {
          name: "Video Show",
          traits: [
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Tags',
              name: 'showTags',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showTimeStamp',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'News Agency',
              name: 'showNewsAgency',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            }

          ],
          media: VideoStoriesIcon,
          attributes: {
            CONSTANTS: CONSTANTS.API_URL,
            title: 'Video Show',
            version: "1000",
            EXPORTED_DATA: {
              SEO_DATA: null
            },
            showTags: true,
            showAuthorName: true,
            showTimeStamp: true,
            showNewsAgency: true,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            stylesheets: [],
            ENV: CONSTANTS.ENV.M360_STAGING,
            mappings: {
              type: "pathquery",
              requestKey: 'msid',
              value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
            },
            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
                STAG_API_URL: PAGE_BUILDER_ENDPOINTS.VIDEO_SHOW_API,
                primary: true,
                isSSR: true,
                SSRDataObj: null,
                isNotListing: true,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultVideoMsid,
                  apiType: "h",
                  ct: 'all',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 4,
                  cloneMapping: [
                    { from: "defaultVideoMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        {
          name: "Web Stories",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIconLess,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Quick Views",
            name: "Grid View",
            version: "1000",
            showDateTime: true,
            background: '#fff',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            ENV: CONSTANTS.ENV.M360_STAGING,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'webstory',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 5,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },
        {
          name: "Web Stories2",
          traits: [
            {
              type: 'text',
              name: 'title',
              changeProp: 1
            },
            {
              type: 'custom-toggle',
              label: 'Date',
              name: 'showDateTime',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'Author',
              name: 'showAuthorName',
              changeProp: 1,
              options: [
                { id: 3, name: 'True', value: true },
                { id: 4, name: 'False', value: false }
              ],
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'custom-toggle',
              label: 'View All',
              name: 'showViewAll',
              changeProp: 1,
              options: [
                { id: "ddd", name: 'True', value: true },
                { id: 1, name: 'False', value: false }
              ],
              showViewURLField: true,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            {
              type: 'color',
              name: "background",
              changeProp: 1
            },
            {
              type: 'custom-select',
              label: 'Select Type',
              name: 'type1',
              changeProp: 1,
              options: [
                { label: 'Pagination', value: "page" },
                { label: 'Next/Prev', value: "next" },
                { label: 'Load More', value: "load" },
                { label: 'No Pagination', value: "noPagination" }
              ],
              value: "page"
            },
            {
              type: 'custom-toggle',
              label: 'Open In New Tab',
              name: 'anchorLinksValue',
              changeProp: 1,
              isNotChecked: function () {
                const context = this;
                return !context.value;
              }
            },
            DATA_MAPPING_TRAIT
          ],
          media: GridViewIcon,
          attributes: {
            STATIC_MAPPING_TRAIT: true,
            CONSTANTS: CONSTANTS.API_URL,
            title: "Web Stories",
            name: "Grid View",
            version: "1000",
            showDateTime: true,
            background: '#fff',
            stylesheets: [],
            type1: "page",
            scrollMode: 0,
            onClick: () => {
              var settingBtn = document.querySelectorAll('[title="Component Properties"]');
              settingBtn[0].click();
            },
            showAuthorName: true,
            showViewAll: false,
            viewAllUrl: "",
            showAdSlot: false,
            headAdScript: "",
            bodyAdScript: "",
            bodyAdMobileScript: "",
            ENV: CONSTANTS.ENV.M360_STAGING,

            ENDPOINTS: [
              {
                name: "MAIN",
                LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                isSSR: true,
                SSRDataObj: null,
                PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                queryParams: {
                  fv: 1000,
                  hostId: defaultHostId,
                  msid: defaultListingMsid,
                  apiType: "h",
                  ct: 'webstory',
                  st: 'date',
                  so: 'dsc',
                  cp: 1,
                  pp: 25,
                  cloneMapping: [
                    { from: "defaultListingMsid", to: "msid" },
                    { from: "defaultHostId", to: "hostId" }
                  ]
                }
              }
            ]
          }
        },

        // {
        //   name: "GridView Other Two",
        //   traits: [
        //     {
        //       type: 'text',
        //       label: "Title",
        //       name: 'mainHeading',
        //       changeProp: 1
        //     },
        //     {
        //       type: 'text',
        //       label: "Title URL",
        //       name: 'mainHeadingHref',
        //       changeProp: 1
        //     },
        //     {
        //       type: 'color',
        //       label: "Title Text Color",
        //       name: 'mainHeadingColor',
        //       changeProp: 1
        //     },
        //     // {
        //     //   type: 'color',
        //     //   label: "BG Color",
        //     //   name: 'BgColor',
        //     //   changeProp: 1
        //     // },
        //     // {
        //     //   type: 'color',
        //     //   label: "Card BG Color",
        //     //   name: 'cardBGColor',
        //     //   changeProp: 1
        //     // },
        //     // {
        //     //   type: 'color',
        //     //   label: "Text Color",
        //     //   name: 'cardTextColor',
        //     //   changeProp: 1
        //     // },
        //     // {
        //     //   type: 'custom-select',
        //     //   label: 'Scroll Mode',
        //     //   name: 'scroll',
        //     //   changeProp: 1,
        //     //   options: [
        //     //     { label: 'Automatic', value: "automatic" },
        //     //     { label: 'Manual', value: "manual" },
        //     //   ]
        //     // },
        //     {
        //       type: 'custom-select',
        //       label: 'Select Type',
        //       name: 'type1',
        //       changeProp: 1,
        //       options: [
        //         { label: 'Pagination', value: "page" },
        //         { label: 'Next/Prev', value: "next" },
        //         { label: 'Load More', value: "load" },
        //         { label: 'No Pagination', value: "noPagination" }
        //       ],
        //       value: "page"
        //     },
        //     {
        //       type: 'text',
        //       label: 'Scroll Count',
        //       name: 'scrollCount',
        //       changeProp: 1
        //     },
        //     {
        //       type: 'custom-toggle',
        //       label: 'Date',
        //       name: 'showDate',
        //       changeProp: 1,
        //       showDate:true,
        //       options: [
        //         { id: 3, name: 'True', value: true },
        //         { id: 4, name: 'False', value: false }
        //       ],
        //       isNotChecked: function () {
        //         const context = this;
        //         return !context.value;
        //       }
        //     },
        //     {
        //       type: 'custom-toggle',
        //       label: 'Ad Slot',
        //       name: 'showAdSlot',
        //       changeProp: 1,
        //       showAdSlotField: true,
        //       isNotChecked: function () {
        //         const context = this;
        //         return !context.value;
        //       }
        //     },
        //     DATA_MAPPING_TRAIT
        //   ],
        //   media: GridViewIconLess,
        //   attributes: {
        //     CONSTANTS: CONSTANTS,
        //     name: "Grid View",
        //     title: "Grid View",
        //     mainHeading: "Grid View",
        //     version: "1000",
        //     mainHeadingHref: "https://www.google.com",
        //     mainHeadingColor: "#000",
        //     BgColor: "#ffffff",
        //     cardBGColor: "#ffffff",
        //     cardTextColor:"#555555",
        //     scroll: "automatic",
        //     showDateTime: true,
        //     scrollCount: 1,
        //     ENV: CONSTANTS.ENV.M360_STAGING,
        //     showAdSlot: false,
        //     adNetwork: "google",
        //     headAdScript: "",
        //     bodyAdScript: "",
        //     bodyAdMobileScript: "",

        //     ENDPOINTS: [
        //       {
        //         name: "MAIN",
        //         LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
        //         STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
        //         isSSR: true,
        //         SSRDataObj: null,
        //         PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
        //         queryParams: {
        //           fv: 1000,
        //           hostId: defaultHostId,
        //           msid: defaultPriortyListMsid,
        //           apiType: "h",
        //           ct: 'all',
        //           st: 'date',
        //           so: 'dsc',
        //           cp: 1,
        //           pp: 3,
        //           cloneMapping: [
        //             {from: "defaultPriortyListMsid", to: "msid"},
        //             {from: "defaultHostId", to: "hostId"}
        //           ]
        //         }
        //       }
        //     ]
        //   }
        // },



        // {
        //   name: "PageNotFound Two ",
        //   traits: [
        //   ],
        //   media: ErrorPageIcon,
        //   attributes: {
        //     CONSTANTS: CONSTANTS.API_URL,
        //     title: "This is the Default title",
        //     version: "1000",
        //     showDateTime: true
        //   }
        // },

        // {
        //   name: "Bread Crumbs Two Amp",
        //   attributes: {
        //     CONSTANTS: CONSTANTS.API_URL,
        //     title: "BreadCrumbs",
        //     version: "1000",
        //     EXPORTED_DATA: {
        //       SEO_DATA: null
        //     },
        //     seperator: ">>",
        //     categoryName: "",
        //     mappings: {
        //       type: "pathquery",
        //       requestKey: 'msid',
        //       value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
        //     },
        //     ENDPOINTS: [
        //       {
        //         name: "MAIN",
        //         LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
        //         STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
        //         isSSR: true,
        //         SSRDataObj: null,
        //         isNotListing: true,
        //         queryParams: {
        //           fv: 1000,
        //           hostId: defaultHostId,
        //           msid: defaultArticleMsid,
        //           apiType: 'nav',
        //           cp: 1,
        //           pp: 4,
        //           cloneMapping: [
        //             { from: "defaultArticleMsid", to: "msid" }
        //           ]
        //         }
        //       }
        //     ]
        //   },
        //   media: BreadCrumbsIcon,
        //   traits: [
        //     {
        //       type: 'custom-select',
        //       label: 'Seperator',
        //       name: 'seperator',
        //       changeProp: 1,
        //       options: [
        //         { label: '>>', value: ">>" },
        //         { label: '>', value: ">" },
        //         { label: '/', value: "/" }
        //       ],
        //       value: ">>"
        //     },
        //     {
        //       type: 'text',
        //       name: 'categoryName',
        //       changeProp: 1
        //     }
        //   ]
        // },


  // ----------------------------theme-Three-comoponents------------------------------------------------
  
          {
            name: "Ad Scripts Three",
            media: AddScriptIcon,
            traits: [{
              type: 'custom-textarea',
              label: 'Header Script',
              name: 'headAdScript',
              changeProp: 1,
              value: ''
            },
            {
              type: 'custom-textarea',
              label: 'Body Script',
              name: 'bodyAdScript',
              changeProp: 1,
              value: ''
            },
            {
              type: 'custom-textarea',
              label: 'Body Script (Mobile)',
              name: 'bodyAdMobileScript',
              changeProp: 1,
              value: ''
            },
            {
              type: 'custom-select',
              label: 'Ad Size',
              name: 'adSize',
              changeProp: 1,
              value: '300*100',
              options: [
                { label: '300*100', value: "300*100" },
                { label: '300*250', value: "300*250" },
                { label: '970*90', value: "970*90" },
              ]
            }],
            attributes: {
              title: "Add Scripts Three",
              version: "1000",
              adSize: "300*100",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              showAdSlot: true
            }
          },
  
          {
            name: "Article Show Three",
            traits: [
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
                options: [
                  { id: 1, name: 'True', value: true },
                  { id: 2, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDateTime',
                changeProp: 1,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Viewport',
                name: 'showViewport',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'text',
                label: 'Images In Viewport',
                name: 'imagesInViewport',
                changeProp: 1,
                value: 'all'
              },
              {
                type: 'custom-toggle',
                label: 'Tags',
                name: 'showTags',
                changeProp: 1,
                options: [
                  { id: 7, name: 'True', value: true },
                  { id: 8, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'News Agency',
                name: 'showNewsAgency',
                changeProp: 1,
                options: [
                  { id: 9, name: 'True', value: true },
                  { id: 19, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Preview',
                name: 'isPreview',
                changeProp: 1,
                options: [
                  { id: 1, name: 'True', value: true },
                  { id: 2, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              }
            ],
            media: ArticleShowIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              title: "Article Show",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              showAuthor: true,
              showDateTime: true,
              isPreview: false,
              showAdSlot: false,
              showTags: true,
              background: '#fff',
              showNewsAgency: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
              adNetwork: "google",
              adSize: "1",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              //GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
                  primary: true,
                  isSSR: true,
                  preview: false,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    msid: defaultArticleMsid,
                    hostId: defaultHostId,
                    fv: 1000,
                    links:1,
                    count:1,
                    cloneMapping: [
                      { from: "defaultArticleMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Quiz Show",
            traits: [
              // {
              //   type: 'custom-toggle',
              //   label: 'Author',
              //   name: 'showAuthor',
              //   changeProp: 1,
              //   options: [
              //     { id: 1, name: 'True', value: true },
              //     { id: 2, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Date',
              //   name: 'showDateTime',
              //   changeProp: 1,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // }
            ],
            media: ArticleShowIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              title: "Quiz Show",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              showAuthor: true,
              showDateTime: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
              adNetwork: "google",
              adSize: "1",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              //GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.QUIZ_SHOW_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.QUIZ_SHOW_API,
                  primary: true,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    msid: defaultQuizId,
                    hostId: defaultHostId,
                    fv: 1000,
                    links:1,
                    count:1,
                    cloneMapping: [
                      { from: "defaultQuizId", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Survey Show",
            traits: [
              // {
              //   type: 'custom-toggle',
              //   label: 'Author',
              //   name: 'showAuthor',
              //   changeProp: 1,
              //   options: [
              //     { id: 1, name: 'True', value: true },
              //     { id: 2, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Date',
              //   name: 'showDateTime',
              //   changeProp: 1,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // }
            ],
            media: ArticleShowIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              title: "Survey Show",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              showAuthor: true,
              showDateTime: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
              adNetwork: "google",
              adSize: "1",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              //GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.SURVEY_SHOW_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.SURVEY_SHOW_API,
                  primary: true,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    msid: defaultSurveyId,
                    hostId: defaultHostId,
                    fv: 1000,
                    links:1,
                    count:1,
                    cloneMapping: [
                      { from: "defaultSurveyId", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Author Show Three",
            traits: [
              {
                type: 'custom-toggle',
                label: 'Department',
                name: 'showDepartment',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Social Icons',
                name: 'showSocialIcon',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Viewport',
                name: 'showViewport',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              }
            ],
            media: AuthorShowIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              title: "Author Show Three",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              showSocialIcon: true,
              showDepartment: true,
              background: '#fff',
              onClick: () => {
                var settingBtn = document.querySelectorAll('[title="Component Properties"]');
                settingBtn[0].click();
              },
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              stylesheets: [],
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.AUTHOR_SHOW_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.AUTHOR_SHOW_API,
                  primary: true,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    msid: defaultAuthorID,
                    fv: 1000,
                    hostId: defaultHostId,
                    cloneMapping: [
                      { from: "defaultAuthorID", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "BreadCrumbs Three",
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              title: "BreadCrumbs",
              version: "1000",
              seperator: ">>",
              categoryName: "",
              TextColor: "#4D90CE",
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultArticleMsid,
                    apiType: 'nav',
                    cp: 1,
                    pp: 4,
                    cloneMapping: [
                      { from: "defaultArticleMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            },
            media: BreadCrumbsIcon,
            traits: [
              {
                type: 'custom-select',
                label: 'Seperator',
                name: 'seperator',
                changeProp: 1,
                options: [
                  { label: '>>', value: ">>" },
                  { label: '>', value: ">" },
                  { label: '/', value: "/" }
                ],
                value: ">>"
              },
              {
                type: 'text',
                name: 'categoryName',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Text Color",
                name: 'TextColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              }
            ]
          },
          {
            name: "Breaking News Ticker",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Heading BG Color",
                name: 'HeadingBGColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Heading Text Color",
                name: 'HeadingTextColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "BG Color",
                name: 'BGColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Text Color",
                name: 'TextColor',
                changeProp: 1
              }
            ],
            media: BreakingNewsIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: false,
              CONSTANTS: CONSTANTS,
              name: "Breaking News Ticker",
              title: "Breaking News Ticker",
              mainHeading: "Breaking News",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              HeadingBGColor: "#0A66C2",
              HeadingTextColor: "#fff",
              TextColor: "#fff",
              BgColor: "#000000",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_TICKER_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_TICKER_API,
                  isSSR: false,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    tenantId: tenantId,
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Experts",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
  
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: ListViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS,
              name: "Experts",
              title: "Experts",
              mainHeading: "Experts",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#fff",
              cardTextColor: "#fff",
              // cardBgColor: "#f1f1f1",
              cardColor: "transparent",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              showAuthor: true,
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Faq List",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: ListViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              name: "Faq List",
              title: "Faq List",
              mainHeading: "Faq List",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              cardTextColor: "#000",
              // cardBgColor: "#f1f1f1",
              cardColor: "transparent",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              showAuthor: true,
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  // primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
                
              ]
            }
          },
  

          {
            name: "Footer Three",
            traits: [
              {
                type: 'text',
                name: 'title',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title Text",
                name: 'TitleText',
                changeProp: 1
              },
              {
                type: 'color',
                label: "BG Color",
                name: 'BgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Text Color",
                name: 'TextColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'About Site',
                name: 'showAbout',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Social Icons',
                name: 'showSocialIcons',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'text',
                label: 'copyRightText',
                name: 'showSocialIcons',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Static Pages',
                name: 'showStaticPages',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'text',
                label: 'Number of Items',
                name: 'noOfHeaderItems',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: FooterIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              version: "1000",
              background: '#151515',
              BgColor: '#151515',
              TextColor: '#fff',
              TitleText: "Recent Post/ Events",
              showAbout: true,
              showSocialIcons: true,
              copyRightText: "",
              footerLogo: "",
              title: "Footer",
              isSticky: true,
              showStaticPages: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
              noOfHeaderItems:5,
  
              ENDPOINTS: [
                {
                  name: "HEADER_DATA",
                  LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    websiteId: retrievedWebsiteId,
                    clientId: retrievedClientId,
                    cloneMapping: [
                      { from: "retrievedWebsiteId", to: "websiteId" },
                      { from: "retrievedClientId", to: "clientId" }
                    ]
                  }
                },
                {
                  name: "FOOTER_MENU_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "userHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 2,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Four Links",
            traits: [
              {
                type: 'color',
                label: "BG Color",
                name: 'BgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Text Color",
                name: 'TextColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: FooterIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              version: "1000",
              background: '#3D3D3D',
              BgColor: '#3D3D3D',
              TextColor: '#fff',
              showAbout: true,
              showSocialIcons: true,
              copyRightText: "",
              footerLogo: "",
              title: "Four Links",
              isSticky: true,
              showStaticPages: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 4,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
  
          {
            name: "GridView Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIconLess,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS,
              name: "GridView Three",
              title: "Grid View",
              mainHeading: "Grid View",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              BgColor: "#ffffff",
              cardBGColor: "#ffffff",
              cardTextColor: "#000",
              scroll: "automatic",
              showDate: true,
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 12,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "Header Three",
            media: HeaderIcon,
            attributes: {
              // CONSTANTS: CONSTANTS.API_URL,
              CONSTANTS: CONSTANTS,
              version: "1000",
              background: '#fff',
              stylesheets: [],
              title: "Header",
              showSearch: true,
              stylesheets: [],
              logoUrl: "https://www.newsclick.in/sites/default/files/2022-11/narendra%20%20modi.jpg",
              isSticky: true,
              ENV: CONSTANTS.ENV.M360_STAGING,
              mainHeadingColor: "#000",
              L1BgColor: "#ffffff",
              L2BgColor: "#f1f1f1",
              L1TextColor: "#555555",
              L2TextColor: "#555555",
              HamburgerBgColor: "#ffffff",
              HamburgerTextColor: "#555555",
              mappings: {
                type: "pathquery",
                requestKey: 'msid',
                value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
              },
              noOfHeaderItems: 6,
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    websiteId: retrievedWebsiteId,
                    clientId: retrievedClientId,
                    cloneMapping: [
                      { from: "retrievedWebsiteId", to: "websiteId" },
                      { from: "retrievedClientId", to: "clientId" }
                    ]
                  }
                },
  
                {
                  name: "HEADER_MENU_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "userHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "BREAD_CRUMBS_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAD_CRUMBS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAD_CRUMBS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultArticleMsid,
                    apiType: 'nav',
                    cp: 1,
                    pp: 4,
                    cloneMapping: [
                      { from: "defaultArticleMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
  
                {
                  name: "TOGGLER_MENU_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            },
            traits: [
              {
                type: 'color',
                label: "L1 BG Color",
                name: 'L1BgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "L1 Text Color",
                name: 'L1TextColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "L2 BG Color",
                name: 'L2BgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "L2 Text Color",
                name: 'L2TextColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Hamburger BG Color",
                name: 'HamburgerBgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Hamburger Text Color",
                name: 'HamburgerTextColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Search',
                name: 'showSearch',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
  
              {
                type: 'custom-toggle',
                label: 'Sticky Header',
                name: 'isSticky',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'text',
                label: 'Number of Items',
                name: 'noOfHeaderItems',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              }
            ]
          },
          
          {
            name: "HeroLayout with Header",
            media: HeaderIcon,
            attributes: {
              CONSTANTS: CONSTANTS.API_URL,
              version: "1000",
              background: '#fff',
              CTAText:'Discover More',
              CTABgColor:'#f29d38',
              CTATextColor:'#fff',
              stylesheets: [],
              title: "Header",
              showSearch: true,
              stylesheets: [],
              logoUrl: "https://www.newsclick.in/sites/default/files/2022-11/narendra%20%20modi.jpg",
              ENV: CONSTANTS.ENV.M360_STAGING,
              noOfHeaderItems: 6,
              
              ENDPOINTS: [
                {
                  name: "HEADER_WRAPPER",
                  LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  isNotListing: true,
                  queryParams: {
                    websiteId: retrievedWebsiteId,
                    clientId: retrievedClientId,
                    cloneMapping: [
                      { from: "retrievedWebsiteId", to: "websiteId" },
                      { from: "retrievedClientId", to: "clientId" }
                    ]
                  }
                },
  
                {
                  name: "HEADER_MENU_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "userHostId", to: "hostId" }
                    ]
                  }
                },
  
                {
                  name: "TOGGLER_MENU_API",
                  LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
                  isSSR: true,
                  SSRDataObj: null,
                  isNotListing: true,
                  queryParams: {
                    hostId: userHostId,
                    cloneMapping: [
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 5,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            },
            traits: [
              {
                type: 'custom-toggle',
                label: 'Search',
                name: 'showSearch',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'color',
                label: "CTA Bg Color",
                name: 'CTABgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "CTA Text Color",
                name: 'CTATextColor',
                changeProp: 1
              },
              {
                type: 'text',
                label: "CTA Text",
                name: 'CTAText',
                changeProp: 1
              },
              {
                type: 'text',
                label: 'Number of Items',
                name: 'noOfHeaderItems',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ]
          },
          {
            name: "HeroLayout Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Text Color",
                name: 'textColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
                showAuthor: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'True', value: true }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showdate: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'True', value: true }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: HeroLayoutIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              name: "Hero layout Three",
              title: "Hero layout Three",
              mainHeading: "Hero layout Three",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              cardTextColor: "#000",
              textColor: "#000000",
              showAuthor: true,
              showDate: true,
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "HeroLayout Hr Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
                showAuthor: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'True', value: true }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: HeroLayoutSecondIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS,
              name: "Hero layout Hr Three",
              title: "Hero layout Hr Three",
              mainHeading: "Hero layout Hr Three",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              // cardBGColor: "pink",
              cardBGColor: "transparent",
              cardActiveColor: "#ffffff",
              showAuthor: true,
              showDate: true,
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          
          {
            name: "Home Faq",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "BG Color",
                name: 'bgColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Strip BG Color",
                name: 'stripbgColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: ListViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              name: "Faq List",
              title: "Faq List",
              mainHeading: "Frequently Asked Questions",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              cardTextColor: "#000",
              bgColor: "#F9F8F8",
              stripbgColor:'#f1f1f1',
              cardColor: "transparent",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              showAuthor: true,
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  // primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
                
              ]
            }
          },
  
  
          {
            name: "Home Grid View Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'text',
                label: "View All",
                name: 'viewAllText',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Tag Color",
                name: 'tagColor',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Tag Text Color",
                name: 'tagTextColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
                options: [
                  { id: 1, name: 'True', value: true },
                  { id: 2, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              // CONSTANTS: CONSTANTS,
              CONSTANTS: CONSTANTS.API_URL,
              name: "Top News",
              title: "Top News",
              mainHeading: "Top News",
              viewAllText:"View All",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              BgColor: "#ffffff",
              cardBGColor: "#ffffff",
              cardTextColor: "#000",
              tagColor:"#f29d38",
              tagTextColor:'#ffffff',
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              showAuthor: true,
              showDate: true,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 6,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },

          {
            name: "Home Photo Gallery",
            traits: [
              {
                type: 'text',
                name: 'title',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              // {
              //   type: 'custom-toggle',
              //   label: 'Date',
              //   name: 'showDate',
              //   changeProp: 1,
              //   showDate: true,
              //   options: [
              //     { id: 1, name: 'True', value: true },
              //     { id: 2, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Author',
              //   name: 'showAuthor',
              //   changeProp: 1,
              //   showAuthor: true,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
  
              // {
              //   type: 'custom-select',
              //   label: 'Select Type',
              //   name: 'type1',
              //   changeProp: 1,
              //   options: [
              //     { label: 'Pagination', value: "page" },
              //     { label: 'Next/Prev', value: "next" },
              //     { label: 'Load More', value: "load" },
              //     { label: 'No Pagination', value: "noPagination" }
              //   ],
              //   value: "page"
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Ad Slot',
              //   name: 'showAdSlot',
              //   changeProp: 1,
              //   showAdSlotField: true,
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Ad Slot2',
              //   name: 'showAdSlot2',
              //   changeProp: 1,
              //   showAdSlotField2: true,
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              title: "Home Gallery",
              name: "Home Gallery",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              background: '#fff',
              mainHeadingColor:'#000000',
              stylesheets: [],
              type1: "page",
              scrollMode: 0,
              onClick: () => {
                var settingBtn = document.querySelectorAll('[title="Component Properties"]');
                settingBtn[0].click();
              },
              showAuthor: true,
              showDate: true,
              showViewAll: false,
              viewAllUrl: "",
              showAdSlot: false,
              showAdSlot2: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              adNetwork2: "google",
              headAdScript2: "",
              bodyAdScript2: "",
              bodyAdMobileScript2: "",
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 3,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },

          {
            name: "Home Web Stories",
            traits: [
              {
                type: 'text',
                name: 'title',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 1, name: 'True', value: true },
                  { id: 2, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
                showAuthor: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              title: "Web Stories",
              name: "Web Stories",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              background: '#fff',
              stylesheets: [],
              type1: "page",
              scrollMode: 0,
              onClick: () => {
                var settingBtn = document.querySelectorAll('[title="Component Properties"]');
                settingBtn[0].click();
              },
              showAuthor: true,
              showDate: true,
              showViewAll: false,
              viewAllUrl: "",
              showAdSlot: false,
              showAdSlot2: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              adNetwork2: "google",
              headAdScript2: "",
              bodyAdScript2: "",
              bodyAdMobileScript2: "",
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 5,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
  
          {
            name: "Quick View Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
  
              {
                type: 'custom-select',
                label: 'Scroll Mode',
                name: 'scroll',
                changeProp: 1,
                options: [
                  { label: 'Automatic', value: "automatic" },
                  { label: 'Manual', value: "manual" },
                ]
              },
              {
                type: 'text',
                label: 'Scroll Count',
                name: 'scrollCount',
                changeProp: 1
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              // {
              //   type: 'custom-toggle',
              //   label: 'Date',
              //   name: 'showDate',
              //   changeProp: 1,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Author',
              //   name: 'showAuthor',
              //   changeProp: 1,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: BreakingNewsIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              // CONSTANTS: CONSTANTS,
              CONSTANTS: CONSTANTS.API_URL,
              name: "Quick View",
              title: "Quick View",
              mainHeading: "Quick View",
              version: "1000",
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              cardBGColor: "#fff",
              cardActiveColor: "#fff",
              showDateField: true,
              showAuthorField: true,
              showDate: true,
              showAuthor: true,
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultPriortyListMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultPriortyListMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "List View Three",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              // {
              //   type: 'color',
              //   label: "Card Color",
              //   name: 'cardColor',
              //   changeProp: 1
              // },
              // {
              //   type: 'color',
              //   label: "BG Color",
              //   name: 'cardBgColor',
              //   changeProp: 1
              // },
              // {
              //   type: 'color',
              //   label: "Card Text Color",
              //   name: 'cardTextColor',
              //   changeProp: 1
              // },
              // {
              //   type: 'custom-select',
              //   label: 'Scroll Mode',
              //   name: 'scroll',
              //   changeProp: 1,
              //   options: [
              //     { label: 'Automatic', value: "automatic" },
              //     { label: 'Manual', value: "manual" },
              //   ]
              // },
              // {
              //   type: 'text',
              //   label: 'Scroll Count',
              //   name: 'scrollCount',
              //   changeProp: 1
              // },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
  
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: ListViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              name: "List View Three",
              title: "List View",
              mainHeading: "List View",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#000",
              cardTextColor: "#000",
              // cardBgColor: "#f1f1f1",
              cardColor: "transparent",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              showAuthor: true,
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "LISTING_SH_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "sh",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          {
            name: "MediaList Three",
            traits: [
              {
                type: 'text',
                name: 'title',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 1, name: 'True', value: true },
                  { id: 2, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              title: "Media List",
              name: "MediaList Three",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              showDateTime: true,
              mainHeadingColor: '#000',
              cardTextColor: '#000',
              stylesheets: [],
              type1: "page",
              scrollMode: 0,
              onClick: () => {
                var settingBtn = document.querySelectorAll('[title="Component Properties"]');
                settingBtn[0].click();
              },
              showAuthorName: true,
              showViewAll: false,
              viewAllUrl: "",
              showAdSlot: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 20,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },

          {
            name: "OtherList View",
            traits: [
              {
                type: 'text',
                label: "Title",
                name: 'mainHeading',
                changeProp: 1
              },
              {
                type: 'text',
                label: "Title URL",
                name: 'mainHeadingHref',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              {
                type: 'custom-toggle',
                label: 'Author',
                name: 'showAuthor',
                changeProp: 1,
  
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Date',
                name: 'showDate',
                changeProp: 1,
                showDate: true,
                options: [
                  { id: 3, name: 'True', value: true },
                  { id: 4, name: 'False', value: false }
                ],
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: ListViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS,
              name: "OtherList View",
              title: "OtherList View",
              mainHeading: "OtherList View",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              mainHeadingHref: "https://www.google.com",
              mainHeadingColor: "#fff",
              cardTextColor: "#fff",
              // cardBgColor: "#f1f1f1",
              cardColor: "transparent",
              scroll: "automatic",
              scrollCount: 1,
              ENV: CONSTANTS.ENV.M360_STAGING,
              showAdSlot: false,
              adNetwork: "google",
              showAuthor: true,
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
                  STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 10,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
          
  {
    name: "PageNotFound Three",
    traits: [
    ],
    media: ErrorPageIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: "This is the Default title",
      version: "1000",
      showDateTime: true
    }
  },
          {
            name: "PhotoList Three",
            traits: [
              {
                type: 'text',
                name: 'title',
                changeProp: 1
              },
              {
                type: 'color',
                label: "Title Text Color",
                name: 'mainHeadingColor',
                changeProp: 1
              },
              // {
              //   type: 'custom-toggle',
              //   label: 'Date',
              //   name: 'showDate',
              //   changeProp: 1,
              //   showDate: true,
              //   options: [
              //     { id: 1, name: 'True', value: true },
              //     { id: 2, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Author',
              //   name: 'showAuthor',
              //   changeProp: 1,
              //   showAuthor: true,
              //   options: [
              //     { id: 3, name: 'True', value: true },
              //     { id: 4, name: 'False', value: false }
              //   ],
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'View All',
              //   name: 'showViewAll',
              //   changeProp: 1,
              //   options: [
              //     { id: "ddd", name: 'True', value: true },
              //     { id: 1, name: 'False', value: false }
              //   ],
              //   showViewURLField: true,
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'custom-toggle',
              //   label: 'Viewport',
              //   name: 'showViewport',
              //   changeProp: 1,
              //   isNotChecked: function () {
              //     const context = this;
              //     return !context.value;
              //   }
              // },
              // {
              //   type: 'text',
              //   label: 'Images In Viewport',
              //   name: 'imagesInViewport',
              //   changeProp: 1,
              //   value: 'all'
              // },
  
              {
                type: 'custom-select',
                label: 'Select Type',
                name: 'type1',
                changeProp: 1,
                options: [
                  { label: 'Pagination', value: "page" },
                  { label: 'Next/Prev', value: "next" },
                  { label: 'Load More', value: "load" },
                  { label: 'No Pagination', value: "noPagination" }
                ],
                value: "page"
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot',
                name: 'showAdSlot',
                changeProp: 1,
                showAdSlotField: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Ad Slot2',
                name: 'showAdSlot2',
                changeProp: 1,
                showAdSlotField2: true,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              {
                type: 'custom-toggle',
                label: 'Open In New Tab',
                name: 'anchorLinksValue',
                changeProp: 1,
                isNotChecked: function () {
                  const context = this;
                  return !context.value;
                }
              },
              DATA_MAPPING_TRAIT
            ],
            media: GridViewIcon,
            attributes: {
              STATIC_MAPPING_TRAIT: true,
              CONSTANTS: CONSTANTS.API_URL,
              title: "Photo List Three",
              name: "PhotoList Three",
              version: "1000",
              EXPORTED_DATA: {
                SEO_DATA: null
              },
              background: '#fff',
              stylesheets: [],
              type1: "page",
              scrollMode: 0,
              onClick: () => {
                var settingBtn = document.querySelectorAll('[title="Component Properties"]');
                settingBtn[0].click();
              },
              showAuthor: true,
              showDate: true,
              showViewAll: false,
              viewAllUrl: "",
              showAdSlot: false,
              showAdSlot2: false,
              adNetwork: "google",
              headAdScript: "",
              bodyAdScript: "",
              bodyAdMobileScript: "",
              adNetwork2: "google",
              headAdScript2: "",
              bodyAdScript2: "",
              bodyAdMobileScript2: "",
              ENV: CONSTANTS.ENV.M360_STAGING,
              showViewport: true,
              imagesInViewport: "all",
  
              ENDPOINTS: [
                {
                  name: "MAIN",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: defaultHostId,
                    msid: defaultListingMsid,
                    apiType: "h",
                    ct: 'all',
                    st: 'date',
                    so: 'dsc',
                    cp: 1,
                    pp: 12,
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                },
                {
                  name: "SEO_URL",
                  LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
                  primary: true,
                  prime: true,
                  loading: "LAZY",
                  isSSR: true,
                  SSRDataObj: null,
                  PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
                  queryParams: {
                    fv: 1000,
                    hostId: userHostId,
                    msid: defaultListingMsid,
                    apiType: "d",
                    cloneMapping: [
                      { from: "defaultListingMsid", to: "msid" },
                      { from: "defaultHostId", to: "hostId" }
                    ]
                  }
                }
              ]
            }
          },
  {
    name: "Podcast Three",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthorName',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Tags',
        name: 'showTags',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showTimeStamp',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      }
  
    ],
    media: VideoStoriesIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: 'Video Show',
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showTags: true,
      showAuthorName: true,
      showTimeStamp: true,
      showNewsAgency: true,
      onClick: () => {
        var settingBtn = document.querySelectorAll('[title="Component Properties"]');
        settingBtn[0].click();
      },
      stylesheets: [],
      ENV: CONSTANTS.ENV.M360_STAGING,
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.VIDEO_SHOW_API,
          primary: true,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultVideoMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 4,
            cloneMapping: [
              { from: "defaultVideoMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "RhsHome Three",
    traits: [
      {
        type: 'text',
        label: "Title",
        name: 'mainHeading',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title URL",
        name: 'mainHeadingHref',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Title Text Color",
        name: 'mainHeadingColor',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      DATA_MAPPING_TRAIT
    ],
    media: ListViewIcon,
    attributes: {
      STATIC_MAPPING_TRAIT: true,
      CONSTANTS: CONSTANTS,
      name: "Home Related News",
      title: "Home Related News",
      mainHeading: "Home Related News",
      version: "1000",
      mainHeadingHref: "https://www.google.com",
      mainHeadingColor: "#000",
      cardContentColor: "#000",
      cardBGColor: "transparent",
      scroll: "automatic",
      scrollCount: 1,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showAdSlot: false,
      showAuthorName: false,
      showHeading: false,
      adNetwork: "google",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultPriortyListMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 6,
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "RhsHome Other Three",
    traits: [
      {
        type: 'text',
        label: "Title",
        name: 'mainHeading',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title URL",
        name: 'mainHeadingHref',
        changeProp: 1
      },
      
      {
        type: 'color',
        label: "Title Text Color",
        name: 'mainHeadingColor',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Ad Slot',
        name: 'showAdSlot',
        changeProp: 1,
        showAdSlotField: true,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthorName',
        changeProp: 1,
  
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Heading',
        name: 'showHeading',
        changeProp: 1,
        showHeading: false,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      DATA_MAPPING_TRAIT
    ],
    media: ListViewIcon,
    attributes: {
      STATIC_MAPPING_TRAIT: true,
      CONSTANTS: CONSTANTS.API_URL,
      name: "Related News",
      title: "Related News",
      mainHeading: "Related News",
      version: "1000",
      mainHeadingHref: "https://www.google.com",
      mainHeadingColor: "#000",
      cardContentColor: "#000",
      cardBGColor: "transparent",
      scroll: "automatic",
      scrollCount: 1,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showAdSlot: false,
      showAuthorName: true,
      showHeading: true,
      adNetwork: "google",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultPriortyListMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 6,
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  {
  
    name: "Slide Show Three",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthor',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDateTime',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        options: [
          { id: 5, name: 'True', value: true },
          { id: 6, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Ad Slot',
        name: 'showAdSlot',
        changeProp: 1,
        showAdSlotField: true,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Viewport',
        name: 'showViewport',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'text',
        label: 'Images In Viewport',
        name: 'imagesInViewport',
        changeProp: 1,
        value: 'all'
      },
      {
        type: 'custom-toggle',
        label: 'Preview',
        name: 'isPreview',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
    ],
    media: SlideShowIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: 'Slide Show',
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showAuthor: true,
      showDateTime: true,
      isPreview: false,
      showNewsAgency: true,
      background: '#fff',
      showAdSlot: false,
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENV: CONSTANTS.ENV.M360_STAGING,
      GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      showViewport: true,
      imagesInViewport: "all",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
          STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
          isSSR: true,
          SSRDataObj: null,
          preview: false,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultSlideshowMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            cloneMapping: [
              { from: "defaultSlideshowMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        },
        {
          name: "SEO_URL",
          LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          primary: true,
          prime: true,
          loading: "LAZY",
          isSSR: true,
          preview: false,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: userHostId,
            msid: defaultSlideshowMsid,
            apiType: "d",
            cloneMapping: [
              { from: "defaultSlideshowMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  
  
  
  
  {
    name: "Static Page Three",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Title',
        name: 'showTitle',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      }
    ],
    media: StaticPageIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      background: '#fff',
      version: "1000",
      title: "Static Page Two",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showTitle: true,
      ENV: CONSTANTS.ENV.M360_STAGING,
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
          STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
          primary: true,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            msid: defaultStaticMsid,
            hostId: defaultHostId,
            fv: 1000,
            cloneMapping: [
              { from: "defaultStaticMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ],
          }
        }
      ]
    }
  },
  
  {
    name: "Breaking News Three",
    traits: [
      {
        type: 'text',
        label: "Title",
        name: 'mainHeading',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title URL",
        name: 'mainHeadingHref',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Title Text Color",
        name: 'mainHeadingColor',
        changeProp: 1
      },
      {
        type: 'color',
        label: "BG Color",
        name: 'BGColor',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Content Color",
        name: 'cardContentColor',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Card BG Color",
        name: 'cardBGColor',
        changeProp: 1
      },
      {
        type: 'custom-select',
        label: 'Scroll Mode',
        name: 'scroll',
        changeProp: 1,
        options: [
          { label: 'Automatic', value: "automatic" },
          { label: 'Manual', value: "manual" },
        ]
      },
      {
        type: 'text',
        label: 'Scroll Count',
        name: 'scrollCount',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      // {
      //   type: 'custom-toggle',
      //   label: 'Ad Slot',
      //   name: 'showAdSlot',
      //   changeProp: 1,
      //   showAdSlotField: true,
      //   isNotChecked: function () {
      //     const context = this;
      //     return !context.value;
      //   }
      // },
      DATA_MAPPING_TRAIT
    ],
    media: BreakingNewsIcon,
    attributes: {
      STATIC_MAPPING_TRAIT: true,
      CONSTANTS: CONSTANTS,
      name: "Trending News",
      title: "Trending News",
      mainHeading: "Trending News",
      version: "1000",
      mainHeadingHref: "https://www.google.com",
      mainHeadingColor: "#000",
      // cardBGColor: "pink",
      cardBGColor: "#fff",
      cardActiveColor: "#fff",
      scroll: "automatic",
      scrollCount: 1,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showAdSlot: false,
      adNetwork: "google",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultPriortyListMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 10,
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  
  {
    name: "Video Show Three",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthorName',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Tags',
        name: 'showTags',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showTimeStamp',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      }
  
    ],
    media: VideoStoriesIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: 'Video Show',
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showTags: true,
      showAuthorName: true,
      showTimeStamp: true,
      showNewsAgency: true,
      onClick: () => {
        var settingBtn = document.querySelectorAll('[title="Component Properties"]');
        settingBtn[0].click();
      },
      stylesheets: [],
      ENV: CONSTANTS.ENV.M360_STAGING,
      GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.VIDEO_SHOW_API,
          primary: true,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultVideoMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 4,
            cloneMapping: [
              { from: "defaultVideoMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "WebStories Three",
    traits: [
      {
        type: 'text',
        label: "Title",
        name: 'mainHeading',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title URL",
        name: 'mainHeadingHref',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Title Text Color",
        name: 'mainHeadingColor',
        changeProp: 1
      },
      {
        type: 'color',
        label: "BG Color",
        name: 'cardBGColor',
        changeProp: 1
      },
      // {
      //   type: 'color',
      //   label: "Card Color",
      //   name: 'cardActiveColor',
      //   changeProp: 1
      // },
      {
        type: 'custom-select',
        label: 'Scroll Mode',
        name: 'scroll',
        changeProp: 1,
        options: [
          { label: 'Automatic', value: "automatic" },
          { label: 'Manual', value: "manual" },
        ]
      },
      {
        type: 'text',
        label: 'Scroll Count',
        name: 'scrollCount',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDate',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      // {
      //   type: 'custom-toggle',
      //   label: 'Ad Slot',
      //   name: 'showAdSlot',
      //   changeProp: 1,
      //   showAdSlotField: true,
      //   isNotChecked: function () {
      //     const context = this;
      //     return !context.value;
      //   }
      // },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      DATA_MAPPING_TRAIT
    ],
    media: CarouselCardIcon,
    attributes: {
      STATIC_MAPPING_TRAIT: true,
      CONSTANTS: CONSTANTS,
      name: "Web Stories",
      title: "Web Stories",
      mainHeading: "Web Stories",
      version: "1000",
      mainHeadingHref: "https://www.google.com",
      mainHeadingColor: "#000",
      showDate: true,
      // cardBGColor: "pink",
      cardBGColor: "#EFEFEF",
      cardActiveColor: "#ffffff",
      scroll: "automatic",
      scrollCount: 1,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showAdSlot: false,
      adNetwork: "google",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultPriortyListMsid,
            apiType: "h",
            ct: 'webstory',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 10,
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "WebStoriesList Three",
    traits: [
      {
        type: 'text',
        label: "Title",
        name: 'mainHeading',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title URL",
        name: 'mainHeadingHref',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Title Text Color",
        name: 'mainHeadingColor',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDate',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-select',
        label: 'Select Type',
        name: 'type1',
        changeProp: 1,
        options: [
          { label: 'Pagination', value: "page" },
          { label: 'Next/Prev', value: "next" },
          { label: 'Load More', value: "load" },
          { label: 'No Pagination', value: "noPagination" }
        ],
        value: "page"
      },
      {
        type: 'custom-toggle',
        label: 'Open In New Tab',
        name: 'anchorLinksValue',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
  
      DATA_MAPPING_TRAIT
    ],
    media: GridViewIcon,
    attributes: {
      STATIC_MAPPING_TRAIT: true,
      CONSTANTS: CONSTANTS,
      name: "Web Stories List",
      title: "Web Stories List",
      mainHeading: "Web Stories List",
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      mainHeadingHref: "https://www.google.com",
      mainHeadingColor: "#000",
      BgColor: "#ffffff",
      cardBgColor: "transparent",
      cardTextColor: "#fff",
      scroll: "automatic",
      showDate: true,
      scrollCount: 1,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showAdSlot: false,
      adNetwork: "google",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultPriortyListMsid,
            apiType: "h",
            ct: 'webstory',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 12,
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        },
        {
          name: "SEO_URL",
          LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          primary: true,
          loading: "LAZY",
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: userHostId,
            msid: defaultPriortyListMsid,
            apiType: "d",
            cloneMapping: [
              { from: "defaultPriortyListMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  
  
  // -------------------------End---theme-Three-comoponents------------------------------------------------
  // ----------------------------theme-Three-amp-comoponents------------------------------------------------
  {
    name: "Article Show Three Amp",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthor',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDateTime',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Viewport',
        name: 'showViewport',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Tags',
        name: 'showTags',
        changeProp: 1,
        options: [
          { id: 7, name: 'True', value: true },
          { id: 8, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Ad Slot',
        name: 'showAdSlot',
        changeProp: 1,
        showAdSlotField: true,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        options: [
          { id: 9, name: 'True', value: true },
          { id: 19, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Preview',
        name: 'isPreview',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      }
      // {
      //   type: 'custom-toggle',
      //   label: 'Open In New Tab',
      //   name: 'anchorLinksValue',
      //   changeProp: 1,
      //   isNotChecked: function () {
      //     const context = this;
      //     return !context.value;
      //   }
      // }
    ],
    media: ArticleShowIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: "Article Show",
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showAuthor: true,
      showDateTime: true,
      showAdSlot: false,
      isPreview: false,
      showTags: true,
      background: '#fff',
      showNewsAgency: true,
      ENV: CONSTANTS.ENV.M360_STAGING,
      showViewport: true,
      imagesInViewport: "all",
      adNetwork: "google",
      adSize: "1",
      headAdScript: "",
      bodyAdScript: "",
      bodyAdMobileScript: "",
      // GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
          STAG_API_URL: RENDERING_ENDPOINTS.ARTICLE_SHOW_API,
          primary: true,
          isSSR: true,
          preview: false,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            msid: defaultArticleMsid,
            hostId: defaultHostId,
            fv: 1000,
            cloneMapping: [
              { from: "defaultArticleMsid", to: "msid" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "Footer Three Amp",
    traits: [
      {
        type: 'text',
        name: 'title',
        changeProp: 1
      },
      {
        type: 'text',
        label: "Title Text",
        name: 'TitleText',
        changeProp: 1
      },
      {
        type: 'color',
        label: "BG Color",
        name: 'BgColor',
        changeProp: 1
      },
      {
        type: 'color',
        label: "Text Color",
        name: 'TextColor',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'About Site',
        name: 'showAbout',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Social Icons',
        name: 'showSocialIcons',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'text',
        label: 'copyRightText',
        name: 'showSocialIcons',
        changeProp: 1
      },
      {
        type: 'custom-toggle',
        label: 'Static Pages',
        name: 'showStaticPages',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      // {
      //   type: 'custom-toggle',
      //   label: 'Open In New Tab',
      //   name: 'anchorLinksValue',
      //   changeProp: 1,
      //   isNotChecked: function () {
      //     const context = this;
      //     return !context.value;
      //   }
      // },
      DATA_MAPPING_TRAIT
    ],
    media: FooterIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      version: "1000",
      background: '#151515',
      BgColor: '#151515',
      TextColor: '#fff',
      TitleText: "Recent Post/ Events",
      showAbout: true,
      showSocialIcons: true,
      copyRightText: "",
      footerLogo: "",
      title: "Footer",
      isSticky: true,
      showStaticPages: true,
      ENV: CONSTANTS.ENV.M360_STAGING,

      ENDPOINTS: [
        {
          name: "HEADER_DATA",
          LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            websiteId: retrievedWebsiteId,
            clientId: retrievedClientId,
            cloneMapping: [
              { from: "retrievedWebsiteId", to: "websiteId" },
              { from: "retrievedClientId", to: "clientId" }
            ]
          }
        },
        {
          name: "FOOTER_MENU_API",
          LIVE_API_URL: RENDERING_ENDPOINTS.FOOTER_MENU_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.FOOTER_MENU_API,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            hostId: userHostId,
            cloneMapping: [
              { from: "userHostId", to: "hostId" }
            ]
          }
        },
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.BREAKING_NEWS_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.BREAKING_NEWS_API,
          isSSR: true,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultListingMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            pp: 2,
            cloneMapping: [
              { from: "defaultListingMsid", to: "msid" },
              { from: "defaultHostId", to: "hostId" }
            ]
          }
        }
      ]
    }
  },
  
  {
    name: "Header Three Amp",
    media: HeaderIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      version: "1000",
      background: '#fff',
      stylesheets: [],
      title: "Header",
      showSearch: true,
      stylesheets: [],
      ENV: CONSTANTS.ENV.M360_STAGING,
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_DATA_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            websiteId: retrievedWebsiteId,
            clientId: retrievedClientId
          }
        },
  
        {
          name: "HEADER_MENU_API",
          LIVE_API_URL: RENDERING_ENDPOINTS.HEADER_MENU_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.HEADER_MENU_API,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            hostId: userHostId
          }
        },
  
        {
          name: "TOGGLER_MENU_API",
          LIVE_API_URL: RENDERING_ENDPOINTS.TOGGLER_MENU_API,
          STAG_API_URL: PAGE_BUILDER_ENDPOINTS.TOGGLER_MENU_API,
          isSSR: true,
          SSRDataObj: null,
          isNotListing: true,
          queryParams: {
            hostId: userHostId
          }
        }
      ]
    },
    traits: [
      {
        type: 'custom-toggle',
        label: 'Search',
        name: 'showSearch',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      }
      // {
      //   type: 'custom-toggle',
      //   label: 'Open In New Tab',
      //   name: 'anchorLinksValue',
      //   changeProp: 1,
      //   isNotChecked: function () {
      //     const context = this;
      //     return !context.value;
      //   }
      // }
    ]
  
  },
  {
    name: "Photo Show Three Amp",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthor',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDateTime',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        options: [
          { id: 5, name: 'True', value: true },
          { id: 6, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Ad Slot',
        name: 'showAdSlot',
        changeProp: 1,
        showAdSlotField: true,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Viewport',
        name: 'showViewport',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'text',
        label: 'Images In Viewport',
        name: 'imagesInViewport',
        changeProp: 1,
        value: 'all'
      },
      {
        type: 'custom-toggle',
        label: 'Preview',
        name: 'isPreview',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
    ],
    media: SlideShowIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: 'Photo Show Three Amp',
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showAuthor: true,
      showDateTime: true,
      showNewsAgency: true,
      isPreview: false,
      showAdSlot: false,
      background: '#fff',
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENV: CONSTANTS.ENV.M360_STAGING,
      GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      showViewport: true,
      imagesInViewport: "all",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_API,
          STAG_API_URL: RENDERING_ENDPOINTS.LISTING_API,
          isSSR: true,
          preview: false,
          SSRDataObj: null,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultSlideshowMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            cloneMapping: [
              { from: "defaultSlideshowMsid", to: "msid" }
            ]
          }
        },
        {
          name: "SEO_URL",
          LIVE_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          STAG_API_URL: RENDERING_ENDPOINTS.LISTING_DETAIL_API,
          primary: true,
          prime: true,
          loading: "LAZY",
          isSSR: true,
          preview: false,
          SSRDataObj: null,
          PB_ENDPOINTS: PAGE_BUILDER_ENDPOINTS,
          queryParams: {
            fv: 1000,
            hostId: userHostId,
            msid: defaultSlideshowMsid,
            apiType: "d",
            cloneMapping: [
              { from: "defaultSlideshowMsid", to: "msid" }
            ]
          }
        }
      ]
    }
  },
  {
    name: "Video Show Three Amp",
    traits: [
      {
        type: 'custom-toggle',
        label: 'Author',
        name: 'showAuthor',
        changeProp: 1,
        options: [
          { id: 1, name: 'True', value: true },
          { id: 2, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Date',
        name: 'showDateTime',
        changeProp: 1,
        options: [
          { id: 3, name: 'True', value: true },
          { id: 4, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'News Agency',
        name: 'showNewsAgency',
        changeProp: 1,
        options: [
          { id: 5, name: 'True', value: true },
          { id: 6, name: 'False', value: false }
        ],
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Ad Slot',
        name: 'showAdSlot',
        changeProp: 1,
        showAdSlotField: true,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'custom-toggle',
        label: 'Viewport',
        name: 'showViewport',
        changeProp: 1,
        isNotChecked: function () {
          const context = this;
          return !context.value;
        }
      },
      {
        type: 'text',
        label: 'Images In Viewport',
        name: 'imagesInViewport',
        changeProp: 1,
        value: 'all'
      }
    ],
    media: SlideShowIcon,
    attributes: {
      CONSTANTS: CONSTANTS.API_URL,
      title: 'Video Show Three Amp',
      version: "1000",
      EXPORTED_DATA: {
        SEO_DATA: null
      },
      showAuthor: true,
      showDateTime: true,
      showNewsAgency: true,
      showAdSlot: false,
      background: '#fff',
      mappings: {
        type: "pathquery",
        requestKey: 'msid',
        value: 'object.attributes.ENDPOINTS.forEach(o => { o.queryParams.msid = msid });'
      },
      ENV: CONSTANTS.ENV.M360_STAGING,
      GLOBAL_DATA_COMPONENT: { init: true, dateTimeFormat: 'MMM DD, YYYY, HH:MM TZ', publishedOrUpdated: 'published' },
      showViewport: true,
      imagesInViewport: "all",
  
      ENDPOINTS: [
        {
          name: "MAIN",
          LIVE_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
          STAG_API_URL: RENDERING_ENDPOINTS.VIDEO_SHOW_API,
          isSSR: true,
          SSRDataObj: null,
          queryParams: {
            fv: 1000,
            hostId: defaultHostId,
            msid: defaultVideoMsid,
            apiType: "h",
            ct: 'all',
            st: 'date',
            so: 'dsc',
            cp: 1,
            cloneMapping: [
              { from: "defaultVideoMsid", to: "msid" }
            ]
          }
        }
      ]
    }
  },
  
  // -------------------------End---theme-Three-amp-comoponents------------------------------------------------
 

      ]
    }
    resolve(componentJson);
  })

}

export default createComponentJson;