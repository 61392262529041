import React, { useEffect, useRef, useState,} from 'react'
import {createSessionifMissing, getMUICache, loadStyles, httpMiddleware, logoutUserSession } from '../../core/Utils';
import Grid from '@mui/material/Grid';
import { CacheProvider } from '@emotion/react';
// import DashBoard from './DashBoard.css'
import LandingPage from './LandingPage.css'
import logoImage from "../../images/dashboard-logo.svg";
import OrgIcon from "../../images/org-icon.png";
import AppsIcon from '@mui/icons-material/Apps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { PAGE_BUILDER_ENDPOINTS, CONSTANTS } from "../../core/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';



const  LandingMainPage = (props) => {
    const cache = getMUICache();
    const [show, setShow]=useState(false);
    const [dropShow, setDropShow]=useState(false);
    const [webListShow, setWebListShow]=useState(false);
    const[activeState, desabileState]=useState(false);
    const[activeStateLog, desabileStateLog]=useState(false);
    const[activeWebList, desabileWebList]=useState(false);
    const [userEmail, setUserEmail] = useState("");
    const refOne=useRef(null);
    const refTwo=useRef(null);
    const refThree=useRef(null);
    const [websitOptions, setOptions] = useState([]);
    const link = document.querySelector("link[rel~='icon']");


    function handleClick(){
        desabileState(activeState => !activeState);
    }
    function handleLogout(){
        desabileStateLog(activeStateLog => !activeStateLog);
    }
    function handleWebList(){
        desabileWebList(activeWebList => !activeWebList);
    }
    useEffect(() => {
        createSessionifMissing().then( res => {
            if( res && res && res?.tokenPayload?.userEmail ) {
                let userEmail = res.tokenPayload.userEmail;                
                setUserEmail(userEmail);

                document.addEventListener("click" , handleClickOutside, true);

                // document.addEventListener("click" , handleClickLogButton, true);
                document.addEventListener("click" , handleClickWebList, true);

                // Make an API request to fetch your data
                const config = {
                    method: 'GET',
                    url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getAllWebsitesByClient",
                    };
                
                    httpMiddleware(config)
                    .then((response) => {
                        if (!response.status) {
                        throw new Error('Network response was not ok');
                        }
                
                        return response.data;
                    })
                    .then((data) => {
                        setOptions(data);
                        // console.log("akhvsb jlk: ", websitOptions);
                        // sessionStorage.setItem('currentWebsiteList', JSON.stringify(data));
                        // handleSelectChange(data[0], "firstLoad"); // Update the options state with the data from the API
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
                    }
                } );
                // add newtitle
                document.title = 'PublishStory Dashboard';

                
                if (link !== null) {
                  link.href = 'https://pagebuilder.publishstory.co/stresources/custom-icons/PS%20favicon.ico'; // Replace '/favicon-page1.ico' with your favicon path for Page 1
                }
    }, []);

    const handleClickOutside = (e) =>{
        if(refOne && refOne.current && refOne.current.contains(e.target)){
            // console.log("clicked in side one")
        }else{
            // console.log("clicked out side one")
            setShow(0);
        }

    }

    // const handleClickLogButton = (e) =>{

    //     if(refTwo && refTwo.current && refTwo.current.contains(e.target)){
    //         console.log("clicked in side two")
    //     }else{
    //         console.log("clicked out side two")
    //         setDropShow(0);
    //     }

    // }

    const handleClickWebList = (e) =>{

        if(refThree && refTwo.current && refTwo.current.contains(e.target)){
            // console.log("clicked in side three")
        }else{
            // console.log("clicked out side two")
            setWebListShow(0);
        }

    }


    function getInitials(input) {
        const words = input.split(" ");
        const initials = words.map(word => word.charAt(0));
        if (initials.length === 1) {
          return initials[0].toUpperCase();
        }
        return (initials[0] + initials[1]).toUpperCase();
      }
      
    let company_name = "Company Name";
    let company_email = "company.name@gmail.com";
    let company_name_initials = "CN";
    if (sessionStorage.getItem("metaData") && JSON.parse(sessionStorage.getItem('metaData')).name) {
        company_name = JSON.parse(sessionStorage.getItem('metaData')).name;
        company_name_initials = getInitials(company_name);
        if(JSON.parse(sessionStorage.getItem("metaData")).tokenPayload && JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail) {
            company_email = JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail;
        }
    }

    function getlogoImageUrl(data) {
        console.log("CONSTANTS : ", CONSTANTS);
        if(data.websiteDomain && data.websiteResouces && data.websiteResouces.schemaLogo && CONSTANTS && CONSTANTS.API_URL && CONSTANTS.API_URL.VSP_IMAGES) {
            if(CONSTANTS.API_URL.VSP_IMAGES == "") {
                return 'https://' + data.websiteDomain + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            } else {
                return CONSTANTS.API_URL.VSP_IMAGES + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            }
        } else {
            return OrgIcon
        }
    }

    const [pageTemplateData, setPageTemplateData] = useState(null);
    const dymincUrl = "https://pagebuilder.publishstory.co";

      const landingNinedotsJson=[
        // {
        //     mediaPath:dymincUrl + "/stresources/custom-icons/home.svg",
        //     mediaHoverPath:dymincUrl + "/stresources/custom-icons/home_selected.svg",
        //     links:[
        //         {
        //             title: "Home",
        //             path:"https://app.publishstory.co/dashboard/"
        //         }
        //     ]
        // },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Authoring_denmark.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Authoring",
                    // path:"https://app.publishstory.co/cms/"
                    path:"/cms/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Reporting_cmsanalytics.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Reporting",
                    // path:"https://app.publishstory.co/reporting/cms-analytics/"
                    path:"/reporting/cms-analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Analytics_ibeat.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/cmsanalytics_selected.svg",
            links:[
                {
                    title: "Analytics",
                    // path:"https://app.publishstory.co/analytics/"
                    path:"/analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Pagebuilder_m360.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/dots-page_builder_selected.svg",
            links:[
                {
                    title: "Page Builder",
                    // path:"https://app.publishstory.co/pagebuilder/"
                    path:"/pagebuilder/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/assetmanager_chronicle.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Asset Manager",
                    path:"/"
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Admin_coral.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/Chronicle_selected.svg",
            links:[
                {
                    title: "Admin",
                    // path:"https://app.publishstory.co/admin/"
                    path:"/admin/"
                }
            ]
        },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Comment moderator.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Comment Moderator",
                    path:"/", 
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/VideoAuthoring_slike.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Video Authoring",
                    path:"/"
                }
            ]
        }

      ]


      const footerLinksJson = [
        {
          links: [
            {
              title: "All rights reserved",
            //   path: "/dashboard/copyright"
            },
            {
                title: "Privacy Policy",
                path: "/dashboard/privacy-policy"
              },
              {
                title: "Terms & Conditions and Grievance Redressal Policy",
                path: "/dashboard/terms-conditions"
              },
              {
                title: "About us",
                // path: "/dashboard/about"
              }

          ]
        },

      ]
// /thumb/104894223.cms?imgsize=422424&amp;width=96&amp;resizemode=4

    if(!(userEmail)) {
        return <></>
    }

    return(
        <>
         <div className='MainBodyWrapper' styles={LandingPage}>
            <div className='LandingPageHeader'>
                <div className='LandingPageHeaderWrapper'>
                    <div className='LandingPageLogo'>
                        <figure><img src={logoImage} /></figure>
                    </div>
                    <div className='RightSideMenuWrap'>
                        <div className='MenuSelectItems'>
                        <p className='inNewsroomTiitle'>Newsroom</p>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <Select labelId="SelectPublisher" id="SelectPublisherHelper" label="publishers" defaultValue={0}>
                                {websitOptions.map((data, i) =>{
                                        return <MenuItem key={ 'jj_' + i} className='slectItemsCommon' value={i}>
                                            <img src={getlogoImageUrl(data)}/>
                                            {data.websiteName}</MenuItem>
                                       
                                })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='moreAppsWrapper' id='_9dots_more_apps_wrapper'></div>
                        {/* <div className={`AppsIconsWrap ${activeState ? "activeState": ''}`} onClick={handleClick}>
                            <button onClick= {()=>setShow(!show)} className='dotsButton' ref={refOne}> <AppsIcon></AppsIcon></button>
                            {show? <div className='ShowIconsOnClick'>
                                <ul>
                                {landingNinedotsJson.map((data, i) =>{
                                    console.log('data',data);
                                    
                                    return <li key={i + "1"}>
                                        {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                       return <a href={item.path} target='_blank' key={l + "3"}><i className='IconWrap'>
                                       <figure className='nineDotsIcon'><img src={data.mediaPath} /></figure>
                                       <figure className='nineDotsIconSelected'><img src={data.mediaHoverPath} /></figure>
                                       </i><span>{item.title}</span></a>
                                    })}
                                    </li>
                                   
                                })}
                                </ul>
                                 
                            </div>:null
                            }
                        </div> */}

                        <div className={`UserNameWrap ${activeStateLog ? "activeStateLog": ''}`} onClick={handleLogout}>
                            <button className='logButtonDrop' onClick= {()=>setDropShow(!dropShow)} ref={refTwo}>{company_name_initials}</button>
                            {dropShow? <div className='logOutWrap'>
                            <div className='userDetailsWrap'>
                            <div className='userNameDetails'>{company_name}</div>  
                              <div className='userEmailDetails'>{company_email}</div>
                            </div>
                            <Tooltip title="Logout">
                            <button onClick={logoutUserSession}><FontAwesomeIcon icon={faPowerOff} /> Logout</button>
                            </Tooltip>
                            </div>:null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='fix-wrapper'>
                <CacheProvider value={cache}>
                    <Grid container item md={12}>
                        <div className='tremsAndConditionStaticContentWrap'>
                            <p className='tremsAndConditionStaticContentheading'>TERMS OF USE</p>
                            <p className='tremsAndConditionStaticContent'>These terms of use (Terms) constitute a legally binding agreement between you and Times Internet Limited and/or
                                its subsidiary(ies) and/or affiliate(s) (hereinafter referred to as the “Company”, “TIL”, “we” or “our”) regarding your
                                use of the PublishStory.co (“Site”) and services offered by the Company, including but not limited to, subscription-
                                based services for TIL’s proprietary platform ‘PublishStory’ (the &quot; Service&quot;). You agree that by registering for the
                                Services, using the Site, or other information provided as part of the Services or by clicking “I agree”, you are
                                entering into a legally binding agreement with TIL based on the Terms herein along with Privacy Policy, master
                                service agreement (if any) as executed separately between you and TIL and/or any other additional terms and
                                condition as applicable on you in relation to the use of the Services which is hereby incorporated by reference
                                (collectively referred as “Agreement”), and become a User and agree to be bound by the Agreement.</p>
                            <p className='tremsAndConditionStaticContent'>You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and
                                are capable of entering, performing and adhering to the Agreement and that you agree to be bound by the
                                following terms and conditions. While individuals under the age of 18 may utilize the Service and/or Site, they shall
                                do so only with the involvement &amp; guidance of their parents and/or legal guardians, under such parent /legal
                                guardian&#39;s registered account. You agree to register prior to use of Services, Site or uploading any content and/or
                                comment (if applicable) and provide your details including but not limited to complete name, age, email address,
                                residential address, and contact number.</p>
                            <p className='tremsAndConditionStaticContent'>Definition of &quot;User&quot; or &quot;you&quot; or “your”: means any person who accesses or avails this Site and/or registers for the
                                Service as made available by the Company.</p>
                            <p className='tremsAndConditionStaticContentheading'>Changes</p>
                            <p className='tremsAndConditionStaticContent'>TIL may add, change or update the Agreement, from time to time entirely at its own discretion. You are
                                responsible for checking these Terms periodically to remain in compliance with these Terms and Agreement in
                                their entirety. Your use of the Site after any amendment to the Terms shall constitute your acceptance of these
                                Terms and you also agree to be bound by any such changes/revisions.</p>
                            <p className='tremsAndConditionStaticContent'>Furthermore, TIL reserves the right to suspend/cancel, or discontinue any or all channels, products or services at
                                any time without notice, make modifications and alterations in any or all of the content, products and services
                                contained on the Site without prior notice. Such changes will be posted to <a href=''>publishstory.co</a> for your reference and
                                convenience so as to enable you to understand your responsibility as a user.</p>

                            <p className='tremsAndConditionStaticContentheading'>Charges</p>
                            <p className='tremsAndConditionStaticContent'>TIL reserves the right to charge fees including subscription fees from a User, by giving reasonable prior notice, in
                                respect of any product, Service or any other aspect of this Site. The specific understanding of the fee and any other
                                commercial understanding for Services as agreed between You and TIL shall be captured as part of the master
                                service agreement signed between the parties.</p>
                            <p className='tremsAndConditionStaticContentheading'>Copyright and Trademarks</p>
                            <p className='tremsAndConditionStaticContent'>Unless otherwise stated, copyright and all intellectual property rights in all material presented on the Site
                                (including but not limited to text, audio, video or graphical images), trademarks and logos appearing on this Site or
                                any other TIL platform while providing Services, are the property of Times Internet Limited, its parent, affiliates
                                and associates and are protected under applicable Indian laws. You agree not to use any framing techniques to
                                enclose any trademark or logo or other proprietary information of TIL; or remove, conceal or obliterate any
                                copyright or other proprietary notice or any credit-line or dateline on other mark or source identifier included on
                                the Site / Service, including without limitation, the size, colour, location or style of all proprietary marks. Any
                                infringement shall be vigorously defended and pursued to the fullest extent permitted by law.</p>

                            <p className='tremsAndConditionStaticContentheading'>Limited Permission to Copy</p>
                            <p className='tremsAndConditionStaticContent'>TIL grants you permission to only access and make personal use of the Site and Services and you agree not to,
                                directly or indirectly download or
                                modify/alter/change/amend/vary/transform/revise/translate/copy/publish/distribute or otherwise disseminate
                                any content on TIL&#39;s Site / Service, or any portion of it; or delete or fail to display any promotional taglines included
                                in the Site / Service either directly or indirectly, except with the express written consent of TIL. However, you may
                                print or download extracts from these pages for your personal/individual, non-commercial use only. You must not
                                retain any copies of these pages saved to disk or to any other storage medium except for the purposes of using the
                                same for subsequent viewing purposes or to print extracts for personal/individual use.</p>
                            <p className='tremsAndConditionStaticContent'>TIL forbids you from any attempts to resell or put to commercial use any part of the Site and/or Services other than
                                as agreed by TIL; any collection and use of any product listings, descriptions, or prices; any derivative use of the
                                Site or its contents; any downloading or copying of account information for the benefit of any other publishers or
                                person; any renting, leasing, or otherwise transferring rights to the Site/Service; displaying the name, logo,
                                trademark or other identifiers of another person in such a manner as to give the viewer the impression that such
                                other person is a publisher or distributor of the Service on the Site, or any data gathering or extraction tools; or
                                any use of meta tags. You may not (whether directly or through the use of any software program) create a
                                database in electronic or structured manual form by regularly or systematically downloading and storing all or any
                                part of the pages from this Site.</p>
                            <p className='tremsAndConditionStaticContent'>No part of the Site/Service may be reproduced or transmitted to or stored in any other website, nor may any of its
                                pages or part thereof be disseminated in any electronic or non-electronic form, nor included in any public or
                                private electronic retrieval system or service without prior written permission.</p>
                            <p className='tremsAndConditionStaticContentheading'>Individual Registration, Access and Exchange of Information</p>
                            <p className='tremsAndConditionStaticContent'>For providing the Services for using the PublishStory.co platform by the user(publisher), registration by the User is
                                required. To register for these Services, you have to open an account by completing the registration process (i.e.
                                by providing us with current, complete and accurate information as prompted by the applicable registration form).
                                You also will choose a password and a username. You are entirely responsible for maintaining the confidentiality of
                                your password and account. By registering, you agree to the Terms in addition to specific terms for use of the
                                PublishStory.co platform by signing a master service agreement and/or any other additional terms as notified by
                                TIL from time to time which can be posted at an appropriate location of the Site. Each registration is for a single
                                individual user only. TIL shall retain your registration information after cancellation or withdrawal of your
                                registration as required under applicable law(s).</p>
                            <p className='tremsAndConditionStaticContent'>To access the PublishStory.co platform and/or use the other services as provided by TIL, you will be asked to enter
                                your individual Username and Password, as chosen by you during your registration. Therefore, we do not permit
                                any of the following: Any other person sharing your account and Password; Any part of the Site (including
                                PublishStory.co platform) being cached in proxy servers and accessed by individuals who have not registered with
                                TIL as users of the Site and _PublishStory.co platform; or access through a single account and Password being
                                made available to multiple users on a network.</p>
                            <p className='tremsAndConditionStaticContent'>If TIL reasonably believes that an account and password are being used/misused in any manner, TIL shall reserve
                                the right to cancel access rights immediately without notice and block access to all users from that IP address.</p>
                            <p className='tremsAndConditionStaticContent'>Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to
                                notify TIL immediately of any unauthorized use of your account or any other breach of security. TIL will not be
                                liable for any loss that you may incur as a result of someone else using your password or account. However, you
                                could be held liable for losses incurred by TIL or another party due to someone else using your account or
                                password.</p>
                            <p className='tremsAndConditionStaticContentheading'>Subscription Service</p>
                            <p className='tremsAndConditionStaticContent'>Subscription-based Service(s) may differ from country to country and the device from which you subscribe.
                                Subscription packages and prices may also vary over time. The subscription prices shall be determined by the
                                Company, at its sole discretion.</p>
                            <p className='tremsAndConditionStaticContent'>When you purchase a subscription, you must provide us with complete and accurate payment and other
                                information required by the Company. By submitting payment details you represent, warrant, and undertake that
                                you are entitled to purchase a subscription using those payment details. If we do not receive payment
                                authorization or any authorization is subsequently cancelled, we may immediately terminate or suspend your
                                access to your subscription-based service or your entire access to Services on Site. In suspicious circumstances, we
                                may contact the issuing bank/payment provider and/or law enforcement authorities or other appropriate third
                                parties. If you are entitled to a refund under these terms and conditions, we will credit that refund to the card or
                                other payment method you used to submit payment unless it has expired in which case we will contact you.
                                Company uses third-party payment gateway services for receiving payment from Users for its subscription-based
                                Services and you hereby agree to your details and information being shared with such third-party payment service
                                providers.</p>





                            <p className='tremsAndConditionStaticContentheading'>Subscription Contract</p>
                            <p className='tremsAndConditionStaticContent'>For our subscription-based Service, the Company shall endeavor to process your subscription promptly, but the
                                Company does not guarantee that your subscription will be activated by any specified time. By submitting your
                                payment and other subscription details, you are making an offer to us to buy a subscription. Your offer will only be
                                accepted by us, and a contract formed when we have successfully verified your payment details and email address
                                and executed a valid master service agreement, at which point we will provide you with access to your
                                subscription. Company reserves the unfettered right to reject any offer made by its Users, at its discretion, for any
                                reason or no reason.</p>
                            <p className='tremsAndConditionStaticContentheading'>Pricing</p>
                            <p className='tremsAndConditionStaticContent'>The subscription price for our subscription-based Services will be made clear to you during the sign-up process and
                                the location of such details on the Site and/or in the master service agreement may vary from time to time, by
                                region or by country. In order to avail of our subscription-based Services, You hereby agree to pay the fees at the
                                rates notified to you at the time you purchase your subscription and at such frequencies as notified from time to
                                time. For certain subscription-based Services offered by the Company, including but not limited to the
                                PublishStory.co platform, the Company however may choose to offer fixed term or fixed payment frequency offers
                                from time to time to Users. The currency in which your subscription is payable will be specified during the order
                                process, depending on the Service and your country of residence. Eligibility for any discounts is ascertained at the
                                time of your subscription and cannot be changed during the term and subsistence of your subscription. We will
                                always tell you in advance of any increase in the price of your subscription and offer you an opportunity to cancel it
                                if you do not wish to pay the revised price.</p>
                            <p className='tremsAndConditionStaticContentheading'>Taxes</p>
                            <p className='tremsAndConditionStaticContent'>Subscription and access to subscription-based Services fall under the purview of applicable tax laws of India. Taxes
                                are applicable for the consumption of content on the website and other Services of the Company for Users based
                                in India and outside the country. Unless otherwise indicated, prices stated by the Company for the Services are
                                inclusive of applicable taxes, including but not limited to Goods and Services Tax (GST) or other applicable taxes.</p>
                            <p className='tremsAndConditionStaticContentheading'>Pricing errors</p>
                            <p className='tremsAndConditionStaticContent'>User hereby agrees and acknowledges that contents on the Site may become subject to technical glitches and/or
                                errors including but not limited to price list, subscription details etc. If we incorrectly or due to a technical glitch or

                                error state a price to you whether online or otherwise, we are not obliged to provide you with a subscription at
                                that price, even if we have mistakenly accepted your offer to buy a subscription at that price, and we reserve the
                                right to subsequently notify you of any pricing error. If we do this, you may cancel the subscription-based Service
                                without any obligation to us and we will refund you any money you have paid us in full, or you may pay the correct
                                price. If you refuse to exercise either of these choices then we may cancel your access to our subscription-based
                                Services and will refund you any money you have paid us in full. Company always endeavours to act in good faith in
                                determining whether a genuine pricing error has occurred or not.</p>
                            <p className='tremsAndConditionStaticContentheading'>Other costs</p>
                            <p className='tremsAndConditionStaticContent'>In addition to any subscription fees you pay for our subscription-based Services, you are responsible for paying any
                                internet connection or other telecommunications charges or any other costs or charges that you may incur by
                                accessing the Services and/or subscription-based Services. For example, if you use any of our mobile services then
                                your network operator may charge you for data or messaging services.</p>
                            <p className='tremsAndConditionStaticContentheading'>Term, renewal and cancellation</p>
                            <p className='tremsAndConditionStaticContent'>You can choose a subscription-based Service for the term and duration offered by the Company and agreed by you
                                in the master service agreement.</p>
                            <p className='tremsAndConditionStaticContentheading'>No unlawful or prohibited use</p>
                            <p className='tremsAndConditionStaticContent'>As a condition of your use of the Services, you will not use the Site/Services for any purpose that is unlawful or
                                prohibited by these terms, conditions, additional terms and conditions, master service agreement, and notices.
                                You may not use the Services in any manner that could damage, disable, overburden, or impair any TIL&#39;s server, or
                                the network(s) connected to any TIL server, or interfere with any other party&#39;s use and enjoyment of any Services.
                                You may not attempt to gain unauthorized access to any Services, other accounts, computer systems or to any of
                                the Services, through hacking, password mining or any other means. You may not obtain or attempt to obtain any
                                materials or information through any means not intentionally made available through the Services. You shall
                                always stay compliant with the applicable laws while using the Services.</p>
                            <p className='tremsAndConditionStaticContentheading'>Material Posted/transmitted at TIL&#39;s Site</p>
                            <p className='tremsAndConditionStaticContent'>All information, data, text, software, music, sound, photographs, graphics, video, messages or other materials
                                (&quot;Content&quot;), whether publicly or privately transmitted / posted/provided, is the sole responsibility of the person
                                from where such content is originated/provided (the Originator). By posting/providing any data or material which
                                contain images, photographs, pictures or that are otherwise graphical in whole or in part (&quot;Images&quot;), you warrant
                                and represent that (a) you will comply with applicable law, (b) you are the copyright owner of such Images, or that
                                the copyright owner of such Images has granted you permission to use such Images or any Content and/or images
                                contained in such Images consistent with the manner and purpose of your use and as otherwise permitted by
                                these Terms and the Services, (c) you have the rights necessary to grant the licenses and sublicenses described in
                                the Agreement , and (d) that each person depicted in such Images, if any, has provided consent to the use of the
                                Images as set forth in the Terms, including, by way of limitation, the distribution, public display and reproduction
                                of such Images.</p>
                            <p className='tremsAndConditionStaticContent'>You represent that you have valid rights and title in any and all Content/Images that you submit on the Site, that
                                you have not infringed on any IPR belonging to any party and further that you will indemnify TIL or its affiliates for
                                all claims arising out of any content that you post on the Site.</p>
                            <p className='tremsAndConditionStaticContent'>You acknowledge and agree that whilst using the Site and Services posting/uploading of any information or
                                material, which is harmful, defamatory, obscene, pornographic, libellous, invasive of another&#39;s privacy, profane,
                                hateful, or racially, ethnically objectionable, disparaging, or otherwise unlawful in any manner is prohibited and

                                any person found to be involved in such activity on this website will solely be liable for actions under the applicable
                                laws.</p>
                            <p className='tremsAndConditionStaticContent'>If you are a publisher of news and current affairs content, then in addition to compliance with other terms
                                mentioned herein, you shall also furnish details of your user accounts on our services to the Ministry of
                                Information and Broadcasting as required under the Information Technology (Intermediary Guidelines and Digital
                                Media Ethics Code) Rules, 2021 (“Rules”) and you shall comply with all applicable laws including said Rules. TIL
                                may, at its discretion, provide such publishers who have provided information under said Rules to the Ministry of
                                Information and Broadcasting, a demonstrable and visible mark of verification as being publishers, which may be
                                visible to all users of the service.</p>
                            <p className='tremsAndConditionStaticContent'>TIL accepts no responsibility for the said Content/Images. However, you understand that all Content / Images
                                posted by you on the Site, become the property of TIL and you agree to grant/assign to TIL and its affiliates, a non-
                                exclusive, royalty-free, perpetual, irrevocable and sub-licensable right to use, reproduce, modify, adapt, publish,
                                translate, create derivative works from, distribute, perform and display such Content / Images (in whole or part)
                                worldwide and/or to incorporate it in other works in any form, media, or technology now known or later
                                developed throughout the world&quot;.</p>
                            <p className='tremsAndConditionStaticContentheading'>Exchange of Information qua CHAT &amp; other Community Services</p>
                            <p className='tremsAndConditionStaticContent'>You represent that you are a responsible adult (18 years or above) and you shall be solely responsible for all
                                Content that you upload post or otherwise transmit.You further represent that you are authorised to post the
                                Content made available by You on the Site.</p>
                            <p className='tremsAndConditionStaticContent'>TIL endeavors to provide a web platform for various community interactions for persons to interact and exchange
                                views with each other. The content posted on such services is by the general public therefore the accuracy,
                                integrity or quality of such content cannot be guaranteed. You understand that by using such services, you may be
                                exposed to objectionable matters.</p>
                            <p className='tremsAndConditionStaticContent'>By using the facility of chat and other community services you agree not to upload, post, or otherwise transmit any
                                matter or views, which are, defamatory, abusive, pervasive, obscene, invasive of another&#39;s privacy, hateful, or
                                racially, ethnically or otherwise objectionable. You may also not indulge in cyber stalking, become or create a
                                nuisance for any visitor / user of the Site.</p>
                            <p className='tremsAndConditionStaticContent'>You may not send, submit, post, or otherwise transmit, material or messages that contain software virus, or any
                                other files that are designed to interrupt, destroy, and negatively affect in any manner whatsoever, any electronic
                                equipment in connection with the use of this Site, or other user&#39;s ability to engage in real time exchanges.</p>
                            <p className='tremsAndConditionStaticContentheading'>Prohibited Activities</p>
                            <p className='tremsAndConditionStaticContent'>You shall not host, display, upload, modify, publish, transmit, store, update or share any information on the
                                Site/Services, that —</p>

                            <ol className='romanNumber' type="I">
                                <li>belongs to another person and to which the user does not have any right;</li>
                                <li>is obscene, pornographic, paedophilic, invasive of another’s privacy including bodily privacy, insulting
                                    or harassing on the basis of gender, racially or ethnically objectionable, relating or encouraging
                                    money laundering or gambling, or an online game that causes user harm, or promoting enmity
                                    between different groups on the grounds of religion or caste with the intent to incite violence;</li>
                                <li>is harmful to child;</li>
                                <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                                <li>deceives or misleads the addressee about the origin of the message or knowingly and intentionally
                                    communicates any misinformation or information which is patently false and untrue or misleading in
                                    nature or, in respect of any business of the Central Government, is identified as fake or false or

                                    misleading by such fact check unit of the Central Government as the Ministry may, by notification
                                    published in the Official Gazette, specify;</li>
                                <li>impersonates another person;</li>
                                <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign
                                    States, or public order, or causes incitement to the commission of any cognisable offence, or prevents
                                    investigation of any offence, or is insulting other nation;</li>
                                <li>contains software virus or any other computer code, file or program designed to interrupt, destroy or
                                    limit the functionality of any computer resource;</li>
                                <li>is in the nature of an online game that is not verified as a permissible online game;</li>
                                <li>is in the nature of advertisement or surrogate advertisement or promotion of an online game that is
                                    not a permissible online game, or of any online gaming intermediary offering such an online game;</li>
                                <li>violates any law for the time being in force;</li>
                                <li>is misinformation powered by AI – Deepfakes</li>
                                <li>threatens public health or safety; promotion of cigarettes or any other tobacco products or
                                    consumption of intoxicant including alcohol and Electronic Nicotine Delivery System (ENDS) &amp; like
                                    products that enable nicotine delivery except for the purpose &amp; in the manner and to the extent, as
                                    may be approved under the Drugs and Cosmetics Act, 1940 and Rules made thereunder;</li>
                                <li>threatens critical information infrastructure</li>
                                <li>contain misleading information regarding the origin of the content; or</li>
                                <li>otherwise contains objectionable content.</li>
                            </ol>
                            <p className='tremsAndConditionStaticContent'>Violation of the above may result in legal consequences and penal provisions under the Indian Penal Code) 1860
                                (IPC), the Information Technology Act, 2000 (IT Act), and other applicable laws. Various penal provisions that may
                                be attracted in case of violation of the above include Sections 153, 292,293, 354C, 419, 465, 469, 505 of the IPC,
                                Sections 43, 66, 66E, 67, 67A, 67B, 66D, 66F of the IT Act, Section 29 of the Trademarks Act, Section 51 of the
                                Copyright Act etc.</p>
                            <p className='tremsAndConditionStaticContent'>TIL is under obligation to report legal violations to law enforcement agencies under the relevant Indian laws.</p>
                            <p className='tremsAndConditionStaticContent'>Any Content and/or comment(s) uploaded by you on the Site shall be subject to relevant laws and may be
                                disabled, or may be subject to investigation under appropriate laws. Furthermore, if you are found to be in non-
                                compliance with the laws and regulations and/or the Agreement, the Company shall have the right to immediately
                                terminate/block your access and usage of the Site/Services and the Company shall have the right to immediately
                                remove any non-compliant Content and or comment (as applicable), uploaded by you subject to retaining such
                                information as required under applicable laws and shall further have the right to take recourse to such remedies as
                                would be available to the Company under the applicable laws. Additionally, unlawful content will be removed on
                                being notified by a government agency or by an order of any court or as required under applicable laws.
                                TIL reserves the right to preserve records of content transmitted by it as required under applicable laws.</p>
                            <p className='tremsAndConditionStaticContentheading'>Grievance Redressal</p>
                            <p className='tremsAndConditionStaticContent'>Redressal Mechanism: Any complaints or concerns with regards to content and or comment or breach of these
                                Terms shall be taken up with the designated Grievance Officer as mentioned below in writing or through email at
                                their respective email ID, signed with the electronic signature to the respective Grievance Officer&quot;</p>
                            <p className='tremsAndConditionStaticContent'>
                                The Grievance Officer appointed by Times Internet Limited is <span>Mr. Angad Singh</span> who can be contacted at <a href='mailto:grievance.publishstory@timesinternet.in'>grievance.publishstory@timesinternet.in</a>

                            </p>
                            <p className='tremsAndConditionStaticContent'>You can also write to:</p>
                            <ul className='tremsAdress'>
                                <li>Grievance Redressal Officer</li>
                                <li>Times Internet Limited</li>
                                <li>391, Udyog Vihar, Phase - III,</li>
                                <li>Gurgaon, Haryana 122002, India</li>
                                <li> Ph: 0124-4518550</li>
                            </ul>
                            <p className='tremsAndConditionStaticContent'>The Company shall not be responsible for any communication, if addressed, to any non-designated person in this
                                regard.</p>
                            <p className='tremsAndConditionStaticContentheading'>Indemnification</p>
                            <p className='tremsAndConditionStaticContent'>You agree to indemnify, defend and hold harmless, the Company, its affiliates, successors, and assigns, and each of
                                their respective investors, directors, officers, employees, agents, and suppliers (including distributors and content
                                licensors) from and against any losses, claims, damages, liabilities, including legal fees and expenses, arising out of:</p>
                            <ol className='abcList laparent' type="a">
                                <li>your violation of any law, regulation or third party right, including without limitation any copyright,
                                    property, or privacy right; or</li>

                                <li>your use or misuse of or access to the Site or the Services;</li>
                                <li>any claim that you have caused damage to a third party.</li>
                                <li>any content or material submitted by you.</li>
                            </ol>
                            <p className='tremsAndConditionStaticContent'>The Company reserves the right, at its own expense, to employ separate counsel and assume the exclusive defence
                                and control of any matter otherwise subject to indemnification by you, and you agree to cooperate with the
                                Company´s defence of these claims.</p>
                            <p className='tremsAndConditionStaticContentheading'>Termination of Account</p>
                            <p className='tremsAndConditionStaticContent'>TIL reserves its right to refuse Service, restrict, suspend, terminate your account; terminate any agreement related
                                to the Services; terminate or suspend your access to the Site; refuse, move or remove for any reason any Content /
                                Image that you submit on or through the Services; refuse, move, or remove any Content / Image that is available
                                on or through the Services; deactivate or delete your accounts and all related information and files in your account
                                (subject to  retaining such information and associated records as required under applicable laws); establish general
                                practices and limits concerning use of the Services at any time and, remove or edit contents or cancel orders
                                (entered by you) in its sole discretion with or without cause, and with or without any prior notice for any violation
                                of the Agreement. Upon such termination or suspension, your right to use the Site/Services will immediately
                                cease.</p>
                            <p className='tremsAndConditionStaticContent'>You can also terminate your account at any time by intimating the same to the &quot;Grievance Officer&quot; but your
                                information may remain stored in archive on our servers even after the deletion or the termination of your
                                account.</p>
                            <p className='tremsAndConditionStaticContent'>TIL may retain any information and associated records that are required to be retained or preserved under
                                applicable laws including post termination of your account and irrespective of whether such information or
                                content has been removed or access to it has been disabled.</p>
                            <p className='tremsAndConditionStaticContentheading'>User Conduct and Obligations</p>
                            <p className='tremsAndConditionStaticContent'>You hereby agree and assure TIL that the Site/Services shall be used for lawful purposes only and that you will not
                                violate laws, regulations, ordinances or other such requirements of any applicable Central, State or local
                                government or any other international laws. You further concur that you will not, through Site/Service:</p>
                            <ol className='numberList numericlist' type='1'>
                                <li>post, distribute, or otherwise make available or transmit any software or other computer files that contain a
                                    virus trojan horses, time bombs, bots, botnets, malicious content, content theft, data manipulation, threats or any
                                    other harmful programs or elements or component;</li>
                                <li>delete from the Site any legal notices, disclaimers, or proprietary notices such as copyright or trademark
                                    symbols, or modify any logos that you do not own or have express permission to modify;</li>
                                <li>not use the Site / Service in any manner that could damage, disable, overburden, or impair and not to undertake
                                    any action which is harmful or potentially harmful to any TIL&#39;s server, or the network(s), computer systems /
                                    resource connected to any TIL server, or interfere with any other party&#39;s use and enjoyment of the Site/Service;</li>
                                <li>obtain or attempt to obtain any materials or information through any means not intentionally made available
                                    through the Site/Service;</li>
                                <li>engage in any activity that causes / may harm minors; or</li>
                                <li>perform any activity which is likely to cause such harm;</li>
                                <li>impersonate any person or entity, including, but not limited to, TIL&#39;s official, forum leader, guide or host, or
                                    falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                                <li>take any action which encourages or consists of any threat of harm of any kind to any person or property;</li>
                                <li>carry out any &quot;denial of service&quot; (DoS, DDoS) or any other harmful attacks on application or internet service or;</li>
                                <li>make any inappropriate, illegal or otherwise prohibited communication to any Newsgroup, Mailing List, Chat
                                    Facility, or other Internet Forum;</li>
                                <li>use the Site/Service for illegal purposes;</li>
                                <li>disrupt, place unreasonable burdens or excessive loads on, interfere with or attempt to make or attempt any
                                    unauthorized access to any TIL website or the website of any TIL&#39;s customer;</li>
                                <li>transmit through the Site/Services, any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar,
                                    obscene, libelous, hateful, or racially, ethnically or otherwise objectionable material of any kind or nature. This
                                    includes text, graphics, video, programs or audio, etc.;</li>
                                <li>collect or attempt to collect personally identifiable information of any person or entity;</li>
                                <li>engage in antisocial, disruptive, or destructive acts, including &quot;flaming,&quot; &quot;spamming,&quot; &quot;flooding,&quot; &quot;trolling,&quot; and
                                    &quot;griefing&quot; as those terms are commonly understood and used on the Internet</li>
                                <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted
                                    through the Site.</li>
                                <li>upload, post, email, transmit or otherwise make available any unsolicited or unauthorised advertising,
                                    promotional materials, &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid schemes,&quot; duplicative messages or any other
                                    form of solicitation.</li>
                                <li>encumber or suffer to exist any lien or security interest on the subject matter of this Agreement; or</li>
                                <li>make any representation or warranty on behalf of TIL.</li>
                                <li>You agree not to post, distribute, transmit or otherwise make available any data, text, message, computer file,
                                    or other material that infringes and/or violates any right of a third party or any domestic or international law, rule,
                                    or regulation, including but not limited to: infringement of any copyright, trademark, patent, trade secret, or other
                                    proprietary rights of any third party, including, but not limited to, the unauthorized copying of copyrighted
                                    material, the digitization and distribution of photographs from magazines, books, or other copyrighted sources,
                                    and the unauthorized transmittal of copyrighted software;</li>
                                <li>infringe the right of privacy (specifically, you must not distribute another person&#39;s personal information of any
                                    kind without their express permission) or publicity;</li>
                                <li>infringe any confidentiality obligation</li>
                            </ol>
                            <p className='tremsAndConditionStaticContent'>Unless otherwise permitted, you will: (i) display/use the Service in the exact form received by you, and not modify
                                or edit any of the foregoing without TIL&#39;s prior written consent; (ii) ensure that the fundamental meaning of the
                                Service is not changed or distorted; (iii) comply with all applicable laws and all limitations and restrictions (if any)
                                placed by TIL on the use, display or distribution of any Service and (iv) not archive any of the Service for access by
                                users at any future date after the Service has been removed. You shall not display the Service in such a manner
                                that does not allow for successful linking and redirection to, and delivery of, authorized web page, nor may you
                                frame any TIL&#39;s and/or third parties web page.</p>
                            <p className='tremsAndConditionStaticContent'>Any transactions relating to sale/purchase of goods or services not directly offered by TIL are to be settled inter-se
                                between the parties to such transaction and all warranties express or implied of any kind, regarding any matter
                                pertaining thereto, including without limitation the implied warranties of merchantability, fitness for a particular
                                purpose, and non-infringement are disclaimed by TIL. TIL merely endeavors to provide a platform where you and

                                other party may interact, exchange information or carry out sale/purchase transactions on such terms and in the
                                manner mutually agreed between you and the other party. TIL does not have any involvement in the actual
                                transactions between the buyers and sellers in any way. It is only you, who is entirely responsible for all the
                                activities, arising out of the transactions of sale/purchase of goods or services offered by any other party and not
                                TIL. TIL will not be liable for any loss that you may incur while selling or purchasing goods /services of the third
                                party.</p>
                            <p className='tremsAndConditionStaticContent'>Under no circumstances will TIL be held responsible or liable, in any way, for any content available on the Site
                                and/or made available by third-party ad providers via the Services, which is derogatory, threatening, defamatory,
                                obscene or offensive or offends public sensibilities or morals and shall also not assume liability for any errors or
                                omissions in any content, or any loss or damage of any kind incurred as a result of the use of any content posted or
                                uploaded on the Site, or any infringement of another&#39;s rights, including intellectual property rights. You specifically
                                agree that TIL is not responsible for any content sent using and/or included in TIL&#39;s Site/Service by any third party.</p>
                            <p className='tremsAndConditionStaticContentheading'>No Controlling Spam Policy or Unsolicited E-mails</p>
                            <p className='tremsAndConditionStaticContent'>You will not use any communication tool or other means available on the Site to transmit, directly or indirectly, any
                                unsolicited bulk communications (including emails and instant messages). You may not harvest information about
                                users of TIL for the purpose of sending or to facilitate the sending of unsolicited bulk communications. We may
                                terminate your access or use of the Site and Services immediately, with or without any notice, and take any other
                                legal action if you, or anyone using your access details to the Site, violates these terms. We may adopt any
                                technical remedy (including any filtering technology or other measures) to prevent unsolicited bulk
                                communications from entering, utilizing or remaining within our computer or communication networks. Such
                                filtering technology or other measures may block, either temporarily or permanently, some e-mail sent to you
                                through the TIL&#39;s Web Sites.</p>
                            <p className='tremsAndConditionStaticContentheading'>Disclaimer of Warranties and Liability</p>
                            <p className='tremsAndConditionStaticContent'>Except as stated otherwise in the Agreement explicitly otherwise, all the warranties without limitation, the implied
                                warranties of merchantability, fitness for a particular purpose, workmanlike effort, title and non-infringement are
                                disclaimed and excluded.</p>
                            <p className='tremsAndConditionStaticContent'>TIL and its parent, affiliates and associates shall not be liable, at any time for any, direct, indirect, punitive,
                                incidental, special, consequential, claims and/or damages (including, without limitation, damages for loss of
                                business projects, damage to your computer system or damages for loss of profits, damages for loss of use, data or
                                profits, arising out of or in any way connected with the use or performance of the TIL&#39;s Sites/Services, with the
                                delay or inability to use the TIL&#39;s Sites/Services or related services, the provision of or failure to provide Services, or
                                for any information, software, products, services and related graphics obtained through the TIL sites/Services, or
                                otherwise arising out of the use of the TIL sites/Services) arising in contract, tort or otherwise from the use of or
                                inability to use the Site/Services, or any of its contents, or from any act or omissions a result of using the Site or
                                any such contents or for any failure of performance, error, omission, interruption, deletion, defect, delay in
                                operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized
                                access to, alteration of, or use of information contained on the site, shutting down of the whole or part of any
                                Services that might result in any loss of information, non-retrieval or loss of data, or arising out of the inability to
                                use any services.</p>
                            <p className='tremsAndConditionStaticContentheading'>Links to Other Sites</p>
                            <p className='tremsAndConditionStaticContent'>All the contents of this Site are only for general information or use. They do not constitute advice and should not
                                be relied upon in making (or refraining from making) any decision. Any specific advice or replies to queries in any
                                part of the Site is/are the personal opinion of such experts/consultants/persons and are not subscribed to by this
                                Site. The information from or through this site is provided on &quot;AS IS&quot; basis, and all warranties and conditions,

                                expressed or implied of any kind, regarding any matter pertaining to any content, goods, service or channel are
                                disclaimed.</p>
                            <p className='tremsAndConditionStaticContent'>Certain links on the Site lead to resources located on servers maintained by third parties, these sites of third
                                party(s) may contain TIL&#39;s-logo, please understand that it is independent from TIL, over whom TIL has no control or
                                connection, business or otherwise as these sites are external to TIL. You agree and understand that by visiting such
                                sites you are beyond the TIL&#39;s website. TIL, therefore neither endorses nor offers any judgement or warranty and
                                accepts no responsibility or liability for the authenticity, availability, suitability, reliability, accuracy of the
                                information, software, products, services and related graphics contained, of any of the goods/services/or for any
                                damage, loss or harm, direct or consequential or any violation of local or international laws that may be incurred
                                by your visit and/or transaction/s on these site(s), as the same is provided on &quot;as is&quot; without warranty of any kind.
                                TIL gives no warranty and makes no representation whether expressed or implied, that the information contained
                                in this site is error free. TIL shall not be responsible nor liable for any consequential damages arising on account of
                                your relying on the contents of the advertisement. Before relying on the material, users should independently
                                verify its relevance for their purposes, and should obtain any appropriate professional advice.</p>
                            <p className='tremsAndConditionStaticContentheading'>Material Submitted by Users</p>
                            <p className='tremsAndConditionStaticContent'>Certain elements of the Site will contain material submitted by Users. TIL accepts no responsibility for the content,
                                accuracy, conformity to applicable laws of such material. You will indemnify and hold harmless TIL against all third-
                                party claims, demands and actions brought against TIL which arise from or relates to the material submitted by
                                you.</p>
                            <p className='tremsAndConditionStaticContentheading'>Advertising Material</p>
                            <p className='tremsAndConditionStaticContent'>Part of the Site contains advertising information or promotion material, or other material submitted to TIL by third
                                parties. Responsibility for ensuring that material submitted for inclusion on Site complies with applicable
                                international and national law is exclusively with the party providing the information/material. Your
                                correspondence or business dealings with, or participation in promotions of, advertisers other than TIL found on or
                                through the website, including payment and delivery of related goods or services, and any other terms, conditions,
                                warranties or representations associated with such dealings, are solely between you and such advertiser. TIL will
                                not be responsible or liable for any claim, error, omission, inaccuracy in advertising material or any loss or damage
                                of any sort incurred as the result of any such dealings or as the result of the presence of such non-TIL advertisers
                                on the Website. TIL reserves the right to omit, suspend or change the position of any advertising material
                                submitted for insertion. Acceptance of advertisements on the Site will be subject to these terms and conditions.</p>
                            <p className='tremsAndConditionStaticContentheading'>Data Protection</p>
                            <p className='tremsAndConditionStaticContent'>TIL may send information and offer products and services to you from time to time. For further details relating to
                                our policy relating to such offer please refer to our privacy statement. Notwithstanding the foregoing, TIL reserves
                                the right to disclose any information including personal details or information in response to/that it is required to
                                be shared, disclosed or made available to any governmental, administrative, regulatory or judicial authority under
                                any law or regulation applicable to TIL.</p>
                            <p className='tremsAndConditionStaticContent'>Further, TIL can (and you authorize TIL to) disclose your name, street address, city, state, zip code, country, phone
                                number, email, and company name to Intellectual Property right&#39;s owners, as we in our sole discretion believe
                                necessary or appropriate in connection with an investigation of fraud, intellectual property infringement, piracy, or
                                other unlawful activity.</p>

                            <p className='tremsAndConditionStaticContentheading'>Relationship</p>
                            <p className='tremsAndConditionStaticContent'>None of the provisions of these Terms shall be deemed to constitute a partnership or agency between you and TIL
                                and you shall have no authority to bind TIL in any manner, whatsoever.</p>

                            <p className='tremsAndConditionStaticContentheading'>Force Majeure</p>
                            <p className='tremsAndConditionStaticContent'>TIL shall have no liability to you for any interruption or delay, to access the Site irrespective of the cause.</p>

                            <p className='tremsAndConditionStaticContentheading'>Indian Law</p>
                            <p className='tremsAndConditionStaticContent'>The Agreement shall be governed by the Laws of India. The Courts of law at Delhi/New Delhi shall have exclusive
                                jurisdiction over any disputes arising under these Terms.</p>

                            <p className='tremsAndConditionStaticContentheading'>Entire Agreement</p>
                            <p className='tremsAndConditionStaticContent'>These Terms of Use together with additional terms (without limitation) such as privacy policy, additional terms and
                                conditions and terms of subscription for use of PublishStory.co platform, available either at Site or entered
                                separately by TIL with You, as applicable, and all other notices, rules, guidelines with respect to Your use of Site
                                and Services, constitutes the entire agreement between TIL and You. You acknowledge and agree that Your usage
                                of the Site is strictly regulated and governed by the terms and conditions of entire agreement as referred above.</p>
                            <p className='tremsAndConditionStaticContent'>These additional documents/policies, wherever applicable, including terms imposed by mobile application stores
                                like Apple, iTunes, Android etc. are deemed to be incorporated under these Terms of Use by way of reference.</p>

                            <p className='tremsAndConditionStaticContentheading'>Limited time to bring your claim</p>
                            <p className='tremsAndConditionStaticContent'>You and TIL agree that any cause of action arising out of or related to the TIL&#39;s web sites, only, must commence
                                within one (1) year after the cause of action accrues otherwise, such cause of action will be permanently barred.</p>

                            <p className='tremsAndConditionStaticContentheading'>Acceptance of Privacy Policy</p>
                            <p className='tremsAndConditionStaticContent'>By using Site and Services, you signify your acceptance of these Terms and the Privacy Policy available at the Site. If
                                you do not agree or are not comfortable with any policy described in herein, your only remedy is to discontinue
                                use of TIL sites. We reserve the right to modify this Terms and the Privacy Policy at any time.</p>

                            <p className='tremsAndConditionStaticContentheading'>General Terms</p>
                            <p className='tremsAndConditionStaticContent'>Rights and obligations under these Terms which by their nature should survive will remain in full effect after
                                termination or expiration of these Terms.</p>
                            <p className='tremsAndConditionStaticContent'>Any express waiver or failure to exercise promptly any right under these Terms will not create a continuing waiver
                                or any expectation of non-enforcement.</p>
                            <p className='tremsAndConditionStaticContent'>If any provision of these Terms is held invalid by any law or regulation of any government, or by any court or
                                arbitrator, the parties agree that such provision will be replaced with a new provision that accomplishes the
                                original business purpose, and the other provisions of these Terms will remain in full force and effect.</p>

                            <p className='tremsAndConditionStaticContentheading'>Notice of Copyright Infringement</p>
                            <p className='tremsAndConditionStaticContent'>TIL is not liable for any infringement of copyright arising out of materials posted on or transmitted through the
                                Site, or items advertised on the Site, by end users or any other third parties. In the event you have any grievance in
                                relation to any Content uploaded on the Site, you may contact us at <a href='mailto:grievance.publishstory@timesinternet.in'>grievance.publishstory@timesinternet.in</a> or
                                write at the following address:</p>



                            <ul className='tremsAdress'>
                                <li>Grievance Redressal Officer</li>
                                <li>Times Internet Limited</li>
                                <li>391, Udyog Vihar, Phase - III,</li>
                                <li>Gurgaon, Haryana 122002, India</li>
                                <li> Ph: 0124-4518550</li>
                            </ul>



                            <p className='tremsAndConditionStaticContent'>We request you to please provide the following information in your complaint:-</p>
                            <ol className='abcList laparent' type='a'>
                                <li>A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the
                                    purposes of the complaint</li>
                                <li>Identification of the copyrighted work claimed to have been infringed.</li>
                                <li>Identification of the material on our website that is claimed to be infringing or to be the subject of infringing
                                    activity.</li>
                                <li>The address, telephone number or e-mail address of the complaining party.</li>
                                <li>A statement that the complaining party has a good-faith belief that use of the material in the manner
                                    complained of is not authorized by the copyright owner, its agent or the law.</li>
                                <li>A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate,
                                    and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.</li>
                            </ol>

                        </div>
                    </Grid>
                </CacheProvider>
            </div>

            <div className='landingPageFooter'>
                <CacheProvider value={cache}>
                <Grid container>
                        <Grid item md={12} xs={12}>
                            <div className='landingFooterText'>
                                <p>publishstory.co , Copyright © 2024 Times Internet Ltd.</p>
                            </div>
                            
                            <div className='landingFooterLinks'>
                                    <ul>
                                    {footerLinksJson.map((data, i) =>{
                                        console.log('data',data);
                                        
                                        return <li>
                                            {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                        return <a rel="noopener noreferrer" target='_blank' href={item.path}><span>{item.title}</span></a>
                                        })}
                                        </li>
                                    
                                    })}
                                    </ul>
                            </div>
                            </Grid>
                            </Grid>
                </CacheProvider>
            </div>
        </div>
        </>
    )

}

export default LandingMainPage;

